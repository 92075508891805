import { Space } from "antd";
import { useTranslations } from "@properate/translations";
import { SearchInput } from "@/components/SearchIndex";
import { useAlarmsTableFiltersContext } from "./NewAlarmsTableFiltersContext";
import { NewAlarmsTableFiltersToggle } from "./NewAlarmsTableFiltersToggle";

export function NewAlarmsTableFilters() {
  const { search, setSearch, filters, setFilters, toggleFilter } =
    useAlarmsTableFiltersContext(true);
  const t = useTranslations();

  return (
    <Space>
      <Space size={[0, 8]} wrap>
        <NewAlarmsTableFiltersToggle
          checked={filters.length === 0}
          onClick={() => setFilters([])}
        >
          {t("org-alarms.toolbar.all")}
        </NewAlarmsTableFiltersToggle>
        <NewAlarmsTableFiltersToggle
          checked={filters.includes("withActiveEvents")}
          onClick={() => toggleFilter("withActiveEvents")}
        >
          {t("org-alarms.toolbar.only-active-alarms")}
        </NewAlarmsTableFiltersToggle>
        <NewAlarmsTableFiltersToggle
          checked={filters.includes("fromUser")}
          onClick={() => toggleFilter("fromUser")}
        >
          {t("org-alarms.toolbar.mine")}
        </NewAlarmsTableFiltersToggle>
      </Space>
      <SearchInput
        style={{ verticalAlign: "middle", width: 400 }}
        allowClear
        placeholder={t("org-alarms.toolbar.search")}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
    </Space>
  );
}
