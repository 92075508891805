import { useGetNewAlarms } from "@/features/alarms";
import { useAlarmsTableFiltersContext } from "../components/AlarmsTableFilters/AlarmsTableFiltersContext";

export function useGetFilteredNewAlarms(buildingId?: number) {
  const { filters, search } = useAlarmsTableFiltersContext();
  const { data, deleteAlarm, error, isLoading } = useGetNewAlarms(
    buildingId,
    filters.includes("fromUser"),
    filters.includes("withActiveEvents"),
    search,
  );

  return {
    filteredAlarms: data,
    isLoading,
    error,
    search,
    deleteAlarm,
  };
}
