import { Select, SelectProps } from "antd";
import { sortNumber } from "@properate/ui";
import { captureMessage } from "@sentry/react";
import { Asset } from "@cognite/sdk";
import { useLocale, useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { FloorPlan } from "./types";

const getFloorType = (externalId: string): "floor" | "sub_floor" | null => {
  if (externalId.includes("=200.K")) {
    return "sub_floor";
  }

  if (externalId.includes("=200.0")) {
    return "floor";
  }

  return null;
};

const getFloorNumber = (externalId: string): number | null => {
  const parse = (id: string) => {
    // Remove "K" (Kjeller) from the floor number of underground floors
    const floorStr = id.replace("K", "");
    const floor = parseInt(floorStr, 10);

    return Number.isNaN(floor) ? null : floor;
  };

  if (externalId.includes("=200.")) {
    const parts = externalId.split("=200.");
    const floorId = parts.at(-1);

    if (floorId) {
      return parse(floorId);
    }
  }

  return null;
};

export function sortFloors(a: string | undefined, b: string | undefined) {
  if (!a || !b) {
    return 0;
  }

  const aType = getFloorType(a);
  const bType = getFloorType(b);

  if (!aType || !bType) {
    return 0;
  }

  if (aType === bType) {
    const aNumber = getFloorNumber(a);
    const bNumber = getFloorNumber(b);

    return sortNumber(aNumber, bNumber);
  }

  return aType === "floor" ? 1 : -1;
}

export function useFloorPlanFloorOptions() {
  const t = useTranslations();
  const locale = useLocale();
  const assets = useFloorPlanAssets();
  const buildingId = useCurrentBuildingId();

  const sortedFloors = assets.data?.floors
    ? [...assets.data.floors].sort((a, b) =>
        sortFloors(a.externalId, b.externalId),
      )
    : [];

  function getFloorName(floor: Asset): string {
    if (!floor.externalId) {
      return floor.description ?? floor.name;
    }

    const type = getFloorType(floor.externalId);
    const floorNumber = getFloorNumber(floor.externalId);

    if (type === null || floorNumber === null) {
      return floor.description ?? floor.externalId ?? "-";
    }

    if (floorNumber === 0) {
      captureMessage(
        "Found a floor with the floor number equals to 0. This is likely a bad entry from the backend.",
        {
          extra: {
            asset: floor,
          },
        },
      );

      return "-";
    }

    // In English we want to show the first floor as `Ground floor`, and the second as `1st floor`.
    const delta = locale === "en" ? -1 : 0;

    return type === "floor"
      ? t(`floor-plan-v2.floors.floor`, { n: floorNumber + delta })
      : t(`floor-plan-v2.floors.sub_floor`, { n: floorNumber });
  }

  const options = sortedFloors.reduce(
    (floors, floor) => {
      if (floor.description && floor.externalId) {
        const description = getFloorName(floor);

        floors[description] = floors[description]
          ? `${floors[description]},${floor.externalId}`
          : floor.externalId;
      }

      return floors;
    },
    {} as Record<string, string>,
  );

  return {
    options,
    getFloorName(record: FloorPlan) {
      if (!record.floor || !assets.data) {
        return "";
      }

      for (const floor of assets.data.floors) {
        if (floor.externalId && record.floor.includes(floor.externalId)) {
          return getFloorName(floor);
        }
      }

      captureMessage("Sub-building is not available for building.", {
        extra: {
          floor: record.floor,
          buildingId,
          availableFloors: options,
        },
      });

      return "";
    },
  };
}

export function FloorPlanFloorSelect(props: SelectProps<string>) {
  const floors = useFloorPlanFloorOptions();

  return (
    <Select {...props}>
      {props.children}
      {Object.entries(floors.options).map(([floor, externalIds]) => (
        <Select.Option key={floor} value={externalIds}>
          {floor}
        </Select.Option>
      ))}
    </Select>
  );
}
