import {
  AlertGroupClientSide,
  AlertRuleSearchResult,
  GetUnassignedAlarmsResponse,
  type OrderBy,
} from "@properate/common";
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import useSWR from "swr";
import { Nullable } from "vitest";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { AlertGroupsLoader } from "@/features/alarms";
import {
  deleteAlertConfiguration,
  getAlarmsInAlertConfigurationStatistics,
  listAlertConfiguration,
  listGroup,
} from "@/eepApi";
import { useHandleApiError } from "@/utils/api";

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

export const getUrl = (
  id: string,
  search?: string,
  sort?: { property: string; order: string },
) => {
  let url = `/asset/${id}/alertConfigurations`;
  if (search) {
    url += `?search=${search}`;
  }
  if (sort) {
    const divider = search ? "&" : "?";
    url += `${divider}sort=${sort.property}&order=${sort.order}`;
  }
  return url;
};

const fetchAlertConfigs = async (
  pageData: AlertGroupsLoader & {
    buildingExternalId: string;
  },
  pageParam?: string,
) => {
  {
    const order_by = pageData.sort
      ? (`${pageData.sort
          ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
      : undefined;

    try {
      const configs = await listAlertConfiguration({
        search: pageData.search || "",
        limit: PAGE_LIMIT,
        filter: {
          building_external_id: pageData.buildingExternalId,
        },
        order_by,
        cursor: pageParam,
      });

      return {
        items: configs.items,
        nextCursor: configs.next_cursor,
      };
    } catch (error) {
      console.error("error", error);
    }

    return {
      items: [],
      nextCursor: undefined,
    };
  }
};

const getQuery = (
  pageData: AlertGroupsLoader & {
    buildingExternalId: string;
  },
) => ({
  queryKey: [
    "alertGroups",
    pageData.buildingExternalId,
    pageData.search,
    pageData.sort,
  ],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchAlertConfigs(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useAlertRulesData = () => {
  const pageData = useLoaderData() as AlertGroupsLoader;
  const currentBuilding = useCurrentBuilding();
  const query = getQuery({
    ...pageData,
    buildingExternalId: currentBuilding.externalId!,
  });
  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    query,
    queryClient,
  );
  const dataTable = data?.pages.flatMap((page) => page.items) || [];

  const onDelete = async (id: string) => {
    await deleteAlertConfiguration(id);
    queryClient.setQueryData<InfiniteData<AlertRuleSearchResult>>(
      query.queryKey,
      (data) => {
        return data
          ? {
              ...data,
              pages: data.pages.map((page) => ({
                ...page,
                items: page.items.filter((item) => item.id !== id),
              })),
            }
          : data;
      },
    );
  };

  return {
    configs: dataTable,
    onDelete,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};

export const useAlertGroups = () => {
  const building = useCurrentBuilding();
  const handleAPIError = useHandleApiError();

  const {
    data: groups = {},
    error,
    isLoading: isLoadingGroups,
  } = useSWR(
    ["alertGroups", building.externalId],
    async () => {
      const { items } = await listGroup({
        filter: {
          building_external_id: building.externalId!,
        },
      });
      return items.reduce(
        (acc, group) => {
          acc[group.id] = group;
          return acc;
        },
        {} as Record<string, AlertGroupClientSide>,
      );
    },
    {
      keepPreviousData: true,
    },
  );

  if (error !== undefined) {
    handleAPIError(error);
  }

  return { groups, isLoadingGroups };
};

export const useAlarmsStatistics = () => {
  const building = useCurrentBuilding();
  const handleAPIError = useHandleApiError();

  const {
    data,
    error,
    isLoading: isLoadingAlarmsStatistics,
  } = useSWR(
    ["alarmsStatistics", building.externalId],
    () =>
      building.externalId
        ? getAlarmsInAlertConfigurationStatistics({
            building_external_id: building.externalId,
          })
        : null,
    {
      keepPreviousData: true,
      errorRetryCount: 2,
    },
  );

  if (error !== undefined) {
    handleAPIError(error);
  }

  return {
    data: data as Nullable<GetUnassignedAlarmsResponse>,
    isLoadingAlarmsStatistics,
  };
};
