import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { AlertGroupPage, AlertGroupsLoader } from "@/features/alarms";
import { deleteGroup, listGroup } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import type { OrderBy } from "@properate/common";

export const getUrl = (
  id: string,
  search?: string,
  sort?: { property: string; order: string },
) => {
  let url = `/asset/${id}/alertConfiguration`;
  if (search) {
    url += `?search=${search}`;
  }
  if (sort) {
    const divider = search ? "&" : "?";
    url += `${divider}sort=${sort.property}&order=${sort.order}`;
  }
  return url;
};

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

const fetchGroups = async (
  pageData: AlertGroupsLoader & {
    buildingExternalId: string;
  },
  pageParam?: string,
) => {
  {
    const order_by = pageData.sort
      ? (`${pageData.sort
          ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
      : undefined;

    try {
      const groups = await listGroup({
        search: pageData.search || "",
        limit: PAGE_LIMIT,
        filter: {
          building_external_id: pageData.buildingExternalId,
        },
        order_by,
        cursor: pageParam,
      });

      const mappedItems = groups.items.map((item) => ({
        ...item,
        membersCount: item.members.length,
      }));

      return {
        items: mappedItems,
        nextCursor: groups.next_cursor,
      };
    } catch (error) {
      console.error("error", error);
    }

    return {
      items: [],
      nextCursor: undefined,
    };
  }
};

const getQuery = (
  pageData: AlertGroupsLoader & {
    buildingExternalId: string;
  },
) => ({
  queryKey: [
    "alertGroups",
    pageData.buildingExternalId,
    pageData.search,
    pageData.sort,
  ],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchGroups(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useGroupsData = () => {
  const pageData = useLoaderData() as AlertGroupsLoader;
  const currentBuilding = useCurrentBuilding();
  const query = getQuery({
    ...pageData,
    buildingExternalId: currentBuilding.externalId!,
  });
  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    query,
    queryClient,
  );
  const dataTable = data?.pages.flatMap((page) => page.items) || [];
  const onDelete = async (id: string) => {
    await deleteGroup(id);
    queryClient.setQueryData<InfiniteData<AlertGroupPage>>(
      query.queryKey,
      (data) => {
        return data
          ? {
              ...data,
              pages: data.pages.map((page) => ({
                ...page,
                items: page.items.filter((item) => item.id !== id),
              })),
            }
          : data;
      },
    );
  };

  return {
    groups: dataTable,
    onDelete,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};
