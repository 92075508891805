import { CloseOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useTranslations } from "@properate/translations";
import { useUser } from "@properate/auth";

type Props = {
  deleteAlarm: () => void;
  disabled?: boolean;
};

export function NewAlarmsTableActionColumn({ deleteAlarm, disabled }: Props) {
  const t = useTranslations();

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={"w-full h-full flex justify-center items-center"}
    >
      <Popconfirm
        title={t("org-alarms.actions.delete-alarm-confirm")}
        onConfirm={() => deleteAlarm()}
        okText={t("org-alarms.actions.ok-text")}
        disabled={disabled}
      >
        <Button
          type="link"
          icon={<CloseOutlined data-testid="close-confirm" />}
          disabled={disabled}
        />
      </Popconfirm>
    </div>
  );
}
