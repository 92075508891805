import { Form, message, Modal, Select } from "antd";
import useSWR from "swr";
import { useTranslations } from "@properate/translations";
import { formatSubBuildingFromExternalId } from "@properate/common";
import { sortString } from "@properate/ui";
import { useSubBuildings } from "@/features/timeseries";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanFloorOptions } from "./FloorPlanFloorSelect";
import { useFloorPlan } from "./FloorPlanContext";

type Props = {
  open: boolean;
  onHide: () => void;
  onOk: (roomId: number) => void;
};

type FormValues = {
  subBuilding: string | undefined;
  floor: string | undefined;
  room: number | undefined;
};

export function FloorPlanMapPinRoomSelectionModal(props: Props) {
  const t = useTranslations();
  const buildingId = useCurrentBuildingId();
  const subBuildings = useSubBuildings({ buildingId });
  const floors = useFloorPlanFloorOptions();
  const floorPlan = useFloorPlan();
  const [form] = Form.useForm<FormValues>();
  const subBuilding = Form.useWatch("subBuilding", form);
  const floor = Form.useWatch("floor", form);
  const { client } = useCogniteClient();

  const rooms = useSWR(
    subBuilding && floor ? ["rooms", subBuilding, floor] : null,
    async ([_, subBuilding, floor]) =>
      client.assets
        .list({
          filter: {
            parentExternalIds:
              /**
               * Floor can be a comma separated list of floor external ids. For example:
               * "AH_1366_Fornebuveien_50+FV51=200.003,AH_1366_Fornebuveien_50+FV50=200.003".
               * And `subBuilding` is always just the sub-building name, for example: "FV50".
               */
              floor.includes(",")
                ? floor
                    .split(",")
                    .filter((id) => id.includes(`+${subBuilding}=`))
                : [floor],
            labels: {
              containsAny: [
                {
                  externalId: "room",
                },
              ],
            },
          },
        })
        .autoPagingToArray({
          limit: -1,
        }),
  );

  function handleSubmit(values: FormValues) {
    if (values.room) {
      props.onOk(values.room);
      props.onHide();
      form.resetFields();
    } else {
      message.warning(t("floor-plan-v2.errors.draft-is-empty"));
    }
  }

  const sortedRooms = rooms.data
    ? [...rooms.data].sort((a, b) => sortString(a.name, b.name))
    : [];

  const existingRoomPins = floorPlan.pins.reduce((acc, pin) => {
    if (pin.type === "room") {
      acc.push(pin.roomId);
    }

    return acc;
  }, [] as number[]);

  return (
    <Modal
      open={props.open}
      onCancel={props.onHide}
      onOk={() => form.submit()}
      title={t("floor-plan-v2.create-room-pin-title")}
      centered
      width={500}
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        initialValues={{
          subBuilding: floorPlan.floor
            ? formatSubBuildingFromExternalId(floorPlan.floor)
            : undefined,
          floor: floorPlan.floor,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item<FormValues>
          label={t("floor-plan-v2.create-room-pin-sub-building")}
          name="subBuilding"
          rules={[{ required: true }]}
        >
          <Select>
            {subBuildings.data.map((subBuilding) => (
              <Select.Option key={subBuilding} value={subBuilding}>
                {subBuilding}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<FormValues>
          label={t("floor-plan-v2.create-room-pin-floor")}
          name="floor"
          rules={[{ required: true }]}
        >
          <Select>
            {Object.entries(floors.options).map(([floor, externalIds]) => (
              <Select.Option key={floor} value={externalIds}>
                {floor}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item<FormValues>
          label={t("floor-plan-v2.create-room-pin-room")}
          name="room"
          rules={[{ required: true }]}
        >
          <Select loading={rooms.isLoading} disabled={!floor}>
            {sortedRooms.map((room) =>
              room.description ? (
                <Select.Option
                  key={room.id}
                  value={room.id}
                  disabled={existingRoomPins.includes(room.id)}
                >
                  {`${room.name} – ${room.description}`}
                </Select.Option>
              ) : null,
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
