import { Spin } from "antd";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { cn } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanPinTimeseriesLatestValue } from "./hooks/useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanPinTimeseries } from "./hooks/useFloorPlanPinTimeseries";
import { useFloorPlanPinAlarms } from "./hooks/useFloorPlanPinAlarms";
import { useFloorPlanPinPopover } from "./FloorPlanMapPinPopoverContext";

export function FloorPlanMapPinValue() {
  const { latestDatapoint, isLoading, error } =
    useFloorPlanPinTimeseriesLatestValue();
  const pin = useFloorPlanPin();

  if (pin.type !== "room") {
    if (isLoading) {
      return (
        <div className="px-4 py-2 -mx-1">
          <Spin size="small" />
        </div>
      );
    }

    if (error) {
      return <div className="px-4 py-2 font-bold text-red-500">!</div>;
    }

    if (!latestDatapoint) {
      return <div className="px-4 py-2 text-muted-foreground">-</div>;
    }
  }

  if (pin.variant === "minimal") {
    return <MinimalView />;
  }

  if (pin.variant === "value") {
    return <ValueView />;
  }

  if (pin.variant === "value+name") {
    return <ValueNameView />;
  }

  console.warn("Unknown view type `%s`.", pin.variant);

  return <ValueView />;
}

function MinimalView() {
  const alarms = useFloorPlanPinAlarms();

  const hasWarning = alarms.find((alarm) => alarm.level === "warning");
  const hasError = alarms.find((alarm) => alarm.level === "error");

  return (
    <div
      className={cn("flex items-center h-10 px-4", {
        "text-green-600": true,
        "text-blue-600": alarms.length > 0,
        "text-yellow-600": hasWarning,
        "text-red-600": hasError,
      })}
    >
      {hasError ? (
        <WarningOutlined />
      ) : hasWarning ? (
        <WarningOutlined />
      ) : alarms.length > 0 ? (
        <InfoCircleOutlined />
      ) : (
        <CheckCircleOutlined />
      )}
    </div>
  );
}

function ValueView() {
  const alarms = useFloorPlanPinAlarms();
  const { formattedValue } = useFloorPlanPinTimeseriesLatestValue();

  const hasWarning = alarms.find((alarm) => alarm.level === "warning");
  const hasError = alarms.find((alarm) => alarm.level === "error");

  return (
    <div className="flex items-center h-10 gap-2 px-4">
      {alarms.length > 0 &&
        (hasError ? (
          <div className="text-red-600">
            <WarningOutlined />
          </div>
        ) : hasWarning ? (
          <div className="text-yellow-600">
            <WarningOutlined />
          </div>
        ) : (
          <div className="text-blue-600">
            <InfoCircleOutlined />
          </div>
        ))}
      {formattedValue}
    </div>
  );
}

function ValueNameView() {
  const pin = useFloorPlanPin();
  const [position, setPosition] = useState("bottom-12 left-0");
  const [isPopoverOpen] = useFloorPlanPinPopover();

  useEffect(() => {
    const top = pin.position.y < 100 ? "top-12" : "bottom-12";
    const left = pin.position.x < 100 ? "left-0" : "right-0";

    setPosition(`${top} ${left}`);
  }, [pin.position.x, pin.position.y]);

  if (isPopoverOpen) {
    return <ValueView />;
  }

  return (
    <div className="relative">
      <div
        className={cn(
          "absolute",
          position,
          "box-border w-48 px-4 py-2 rounded-md",
          "border border-solid border-border shadow-md",
          "bg-background text-foreground",
          "text-left",
        )}
      >
        {pin.type === "room" ? (
          <ValueNameViewRoomDescription roomId={pin.roomId} />
        ) : (
          <ValueNameViewTimeseriesDescription />
        )}
      </div>
      <ValueView />
    </div>
  );
}

export function ValueNameViewRoomDescription(props: { roomId: number }) {
  const t = useTranslations();
  const { client } = useCogniteClient();

  const room = useSWR(["room", props.roomId], async () =>
    client.assets
      .retrieve([
        {
          id: props.roomId,
        },
      ])
      .then((assets) => assets.at(0)),
  );

  return (
    <>
      {room.data
        ? room.data.name +
          (room.data.description ? ` ${room.data.description}` : "")
        : t("ui.loading")}
    </>
  );
}

function ValueNameViewTimeseriesDescription() {
  const t = useTranslations();
  const timeseries = useFloorPlanPinTimeseries();

  return (
    <>
      {timeseries.data
        ? formatExtendedTimeseriesDescription(timeseries.data)
        : t("ui.loading")}
    </>
  );
}
