import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  Space,
  Switch,
  Typography,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { Alarm, AlertRule } from "@properate/common";
import { useGetFirestoreCollection } from "@properate/ui";
import { useMemo } from "react";
import { collection, query, where } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase/src";
import { CompactContent } from "@/components/CompactContent";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { getAlertConfiguration } from "@/eepApi";
import { BellIcon } from "../rules/icons/BellIcon";
import {
  FormValues,
  mapValues,
  useCreateAlertRule,
  useDeleteAlertRule,
  useUpdateAlertRule,
} from "./utils";
import { AlertRuleDetailsGroups } from "./AlertRuleDetailsGroups";
import { AlertRuleDetailsExceptions } from "./AlertRuleDetailsExceptions";
import { AlarmsSelector } from "./AlarmsSelector";
import { AlarmsQueryBasedSelector } from "./AlarmsQueryBasedSelector";
import { AlertRuleDetailsFallbackGroup } from "./AlertRuleDetailsFallbackGroup";

type LoaderData = {
  rule?: AlertRule;
};

type Props = {
  rule?: AlertRule;
};

const DEFAULT_VALUES = {
  name: "",
  muted: false,
  type: "query",
  alarm_rule_selector: {
    severity: undefined,
    category: undefined,
  },
  alarm_rule_ids: [],
  group_rules: [],
  group_rules_exceptions: [],
};

function AlertRuleForm(props: Props) {
  const t = useTranslations();
  const [form] = Form.useForm<FormValues>();
  const type = Form.useWatch("type", form);
  const buildingId = useCurrentBuildingId();
  const params = useParams();
  const navigate = useNavigate();

  const update = useUpdateAlertRule();
  const create = useCreateAlertRule();
  const remove = useDeleteAlertRule();

  const isSubmitting = update.isMutating || create.isMutating;

  const severity = Form.useWatch(["alarm_rule_selector", "severity"], form);
  const category = Form.useWatch(["alarm_rule_selector", "category"], form);

  const queryAlarms = useGetFirestoreCollection<Alarm>(
    useMemo(() => {
      if (!severity?.length && !category) {
        return null;
      }

      const filters = [where("buildingId", "==", buildingId)];

      if (severity?.length) {
        filters.push(where("severity", "in", severity));
      }

      if (category?.length) {
        filters.push(where("category", "in", category));
      }

      return query(collection(browserFirestore, "alarms"), ...filters);
    }, [severity, category, buildingId]),
  );

  const allAlarms = useGetFirestoreCollection<Alarm>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, "alarms"),
          where("buildingId", "==", buildingId),
        ),
      [buildingId],
    ),
  );

  function handleSubmit(values: FormValues) {
    if (props.rule) {
      return update.trigger({
        rule: {
          ...props.rule,
          ...mapValues(values),
          alarm_rule_ids:
            values.type === "query"
              ? queryAlarms.data.map((alarm) => alarm.snapshotId)
              : values.alarm_rule_ids,
        },
        id: params.alertRuleId as string,
      });
    }

    return create.trigger({
      ...mapValues(values),
      alarm_rule_ids:
        values.type === "query"
          ? queryAlarms.data.map((alarm) => alarm.snapshotId)
          : values.alarm_rule_ids,
    });
  }

  function handleRemove() {
    if (params.alertRuleId) {
      remove.trigger({
        id: params.alertRuleId,
      });
    }
  }

  const onCancel = () => {
    navigate(`/asset/${buildingId}/alertConfiguration`);
  };

  return (
    <Form<FormValues>
      form={form}
      initialValues={props.rule ?? DEFAULT_VALUES}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={console.error}
      disabled={isSubmitting || remove.isMutating}
      className="h-full w-full flex flex-col mb-24"
    >
      <PageHeader
        title={
          <Space align="center">
            <Link to={`/asset/${buildingId}/alertConfiguration`}>
              {t("alert-rule.title")}
            </Link>
            <RightOutlined />
            {props.rule
              ? t("alert-rule.title-update")
              : t("alert-rule.title-create")}
            <div className="flex items-center">
              <BellIcon />
            </div>
          </Space>
        }
        extra={[
          <Button key="filter" htmlType="button" onClick={onCancel}>
            {t("ui.cancel")}
          </Button>,
          props.rule && (
            <Button
              key="delete"
              type="primary"
              htmlType="button"
              disabled={isSubmitting}
              loading={remove.isMutating}
              danger
              ghost
              onClick={handleRemove}
            >
              {t("ui.delete")}
            </Button>
          ),
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            ghost
            disabled={remove.isMutating}
            loading={isSubmitting}
          >
            {props.rule ? t("ui.save") : t("ui.create")}
          </Button>,
        ]}
      />
      <CompactContent className="h-full flex-1 space-y-6 mt-4">
        <Card>
          <div className="max-w-[450px]">
            <Form.Item<FormValues>
              label={t("alert-rule.field-name")}
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item<FormValues>
              label={t("alert-rule.field-mute")}
              name="muted"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
            <Form.Item<FormValues>
              label={t("alert-rule.field-type")}
              name="type"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio.Button value="select">
                  {t("alert-rule.field-type-select")}
                </Radio.Button>
                <Radio.Button value="query">
                  {t("alert-rule.field-type-query")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {type === "select" ? (
              <AlarmsSelector alarms={allAlarms} />
            ) : (
              <AlarmsQueryBasedSelector queryAlarms={queryAlarms} form={form} />
            )}
          </div>
        </Card>
        <div className="space-y-1">
          <Typography.Text strong>
            {t("alert-rule.section-groups-title")}
          </Typography.Text>
          <Card>
            <Form.List name="group_rules" rules={[]}>
              {(fields, { add, remove }) => (
                <AlertRuleDetailsGroups
                  form={form}
                  fields={fields}
                  onAdd={add}
                  onRemove={remove}
                />
              )}
            </Form.List>
          </Card>
        </div>
        <div className="space-y-1">
          <Typography.Text strong>
            {t("alert-rule.section-exceptions-title")}
          </Typography.Text>
          <Card>
            <Form.List name="group_rules_exceptions">
              {(fields, { add, remove }) => (
                <AlertRuleDetailsExceptions
                  form={form}
                  fields={fields}
                  onAdd={add}
                  onRemove={remove}
                />
              )}
            </Form.List>
          </Card>
        </div>
        <AlertRuleDetailsFallbackGroup form={form} />
      </CompactContent>
    </Form>
  );
}

export function AlertRuleEditor() {
  const data = useLoaderData() as LoaderData;

  return <AlertRuleForm rule={data.rule} />;
}

export function AlertRuleCreator() {
  return <AlertRuleForm />;
}

AlertRuleEditor.loader = async function Loader(
  args: LoaderFunctionArgs,
): Promise<LoaderData> {
  const id = args.params.alertRuleId;

  if (id) {
    return {
      rule: await getAlertConfiguration(id),
    };
  }

  return {};
};
