import { CenteredSpinner } from "@properate/ui";
import { Form, Select } from "antd";
import { Alarm, WithSnapshotId } from "@properate/common";
import { FormValues } from "./utils";

type Props = {
  alarms: {
    data: WithSnapshotId<Alarm>[];
    isLoading: boolean;
    error: string | Error | null;
  };
};

export function AlarmsSelector({ alarms }: Props) {
  return alarms.isLoading ? (
    <CenteredSpinner />
  ) : (
    <Form.Item<FormValues> name={["alarm_rule_ids"]}>
      <Select
        mode="multiple"
        allowClear
        className="w-full ml-16"
        loading={alarms.isLoading}
      >
        {alarms.data?.map((alarm) => (
          <Select.Option key={alarm.snapshotId} value={alarm.snapshotId}>
            {alarm.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
