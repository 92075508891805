import useSWR from "swr";
import { AlarmRule } from "@properate/common";
import { deleteAlarmConfiguration, listAlarmConfiguration } from "@/eepApi";

export function useGetNewAlarms(
  buildingId?: number,
  fromUser?: boolean,
  withActiveEvents?: boolean,
  search?: string,
) {
  const { data, error, isLoading, mutate } = useSWR(
    [
      "getAlarms",
      buildingId ?? "all",
      !!fromUser,
      !!withActiveEvents,
      search ?? "",
    ],
    () => {
      if (buildingId) {
        return listAlarmConfiguration({
          filter: {
            building_id: buildingId,
            only_active_alarms: !!fromUser,
            only_alarms_for_user: !!withActiveEvents,
          },
        });
      }
      return listAlarmConfiguration({});
    },
  );

  const deleteAlarm = async (id: string) => {
    await deleteAlarmConfiguration({ id });
    if (data) {
      await mutate(
        {
          ...data,
          items: data.items.filter((item: AlarmRule) => item.alarm_id !== id),
        },
        false,
      );
    } else {
      throw new Error("Data is not available");
    }
  };

  return {
    data: (data?.items || []) as AlarmRule[],
    isLoading,
    error,
    deleteAlarm,
  };
}
