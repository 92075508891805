import { CommonAlarmRuleTypeSpecificSchedule } from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";

interface Props {
  schedule: CommonAlarmRuleTypeSpecificSchedule;
}

export function NewAlarmsTablePlanColumn({ schedule }: Props) {
  const t = useTranslations();

  if (schedule.always_active) {
    return "Always active";
  }
  let schedulDescription = "";

  if (schedule.active_periods) {
    schedulDescription += schedule.active_periods
      .map(
        (period) =>
          `${t(
            `common.day-periods.${period.day.toLowerCase()}` as MessageKey,
          )} ${
            "all_day" in period
              ? ""
              : period.start_time + " - " + period.end_time
          }`,
      )
      .join(", ");
  }

  return schedulDescription;
}
