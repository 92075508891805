import { CenteredSpinner } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { AlarmDeviation, TriggerInfoForAlarmEvent } from "@properate/common";
import { Empty } from "antd";
import { dateFormat } from "@/utils/date";
import { Note, NoteSidebarFilterButton } from "@/features/notes";
import { AlarmDeviationWithNotes } from "../hooks/useCogniteAlarmEvents";
import { Box, BoxVariant } from "../components/InfoBox";
import {
  ResponsiveTable,
  ResponsiveTableColumn,
} from "../components/ResponsiveTable";
import { useFormValue } from "../FormContext";
import { useTimeseries } from "../hooks";

export enum DeviationTableColumn {
  Timeframe = "timeframe",
  Value = "measurement-value",
  Threshold = "threshold",
  Delay = "delay",
  LastDatapoint = "last-datapoint",
  Notes = "note",
}

export type DeviationProps = {
  deviations: AlarmDeviationWithNotes[];
  highlighted?: AlarmDeviationWithNotes;
  isLoading: boolean;
  error: any;
};

export type DeviationTableProps = {
  columns: DeviationTableColumn[];
  highlighted?: AlarmDeviationWithNotes;
  timeseriesIdFormField?: string;
} & DeviationProps;

type DeviationTableEntry = {
  id: number;
  [DeviationTableColumn.Timeframe]?: string;
  [DeviationTableColumn.Value]?: string;
  [DeviationTableColumn.Threshold]?: string;
  [DeviationTableColumn.Delay]?: number;
  [DeviationTableColumn.LastDatapoint]?: string;
  [DeviationTableColumn.Notes]?: Note[];
};

export function AlarmDeviationTable({
  columns,
  highlighted,
  timeseriesIdFormField,
  deviations,
  isLoading,
  error,
}: DeviationTableProps) {
  const t = useTranslations();
  const [timeseriesId] = useFormValue<number | undefined>(
    timeseriesIdFormField,
  );
  const {
    timeseries,
    isLoading: timeseriesLoading,
    error: timeseriesError,
  } = useTimeseries({ timeseriesId });

  if (isLoading || timeseriesLoading) {
    return <CenteredSpinner />;
  }

  if (error || timeseriesError) {
    return (
      <Box variant={BoxVariant.Error}>
        <p>{t("alarm-details.deviations-page.list.error")}</p>
      </Box>
    );
  }

  if (deviations?.length === 0) {
    return (
      <div className="w-full flex flex-col gap-0 justify-center items-center">
        <Empty
          image={
            <div
              className={
                "w-full h-full flex justify-center items-center icon-large"
              }
            >
              {Empty.PRESENTED_IMAGE_SIMPLE}
            </div>
          }
          description=""
        />
        <p className="opacity-50 m-0 p-0">
          {t(`alarm-details.deviations-page.list.empty-message`)}
        </p>
      </div>
    );
  }

  const unit = timeseries?.unit ?? "";

  function getTimeframe(deviation: AlarmDeviation): string {
    const startTime = dateFormat(deviation.startTime, true);
    const endTime = !deviation.endTime
      ? t("alarm-details.deviations-page.list.timeframe-ongoing")
      : dateFormat(deviation.endTime, true);

    return `${startTime} - ${endTime}`;
  }

  const deviationEntries = deviations.map((deviation) => {
    const values: DeviationTableEntry = {
      id: deviation.id,
    };
    const triggerInfo: TriggerInfoForAlarmEvent = JSON.parse(
      deviation.metadata.trigger_info,
    );
    if (columns.includes(DeviationTableColumn.Timeframe)) {
      values[DeviationTableColumn.Timeframe] = getTimeframe(deviation);
    }
    if (columns.includes(DeviationTableColumn.Value)) {
      values[DeviationTableColumn.Value] = `${
        triggerInfo.value ?? "--"
      } ${unit}`;
    }
    if (columns.includes(DeviationTableColumn.Threshold)) {
      values[DeviationTableColumn.Threshold] = `${
        triggerInfo.threshold ?? "--"
      } ${unit}`;
    }
    if (columns.includes(DeviationTableColumn.Delay)) {
      values[DeviationTableColumn.Delay] = triggerInfo.delay;
    }
    if (columns.includes(DeviationTableColumn.LastDatapoint)) {
      values[DeviationTableColumn.LastDatapoint] = dateFormat(
        triggerInfo.last_datapoint,
        true,
        "--",
      );
    }
    if (columns.includes(DeviationTableColumn.Notes)) {
      values[DeviationTableColumn.Notes] = deviation.notes;
    }

    return values;
  });

  const highlightedDeviationEntry = highlighted
    ? {
        id: highlighted.id,
      }
    : undefined;

  const tableColumns = columns.map((column) => {
    const tableColumn: ResponsiveTableColumn<DeviationTableEntry> = {
      valueLookup: column,
      label: t(`alarm-details.deviations-page.list.${column}`),
    };

    if (column === DeviationTableColumn.Notes) {
      tableColumn.renderValue = (entry) => {
        const notes = entry[DeviationTableColumn.Notes];
        return (
          <NoteSidebarFilterButton
            notes={notes ?? []}
            filterId={`deviation-filter-${entry.id}`}
          />
        );
      };
    }
    return tableColumn;
  });

  return (
    <div className="w-full h-full overflow-y-scroll">
      <ResponsiveTable<DeviationTableEntry>
        tableColumns={tableColumns}
        keyLookup={"id"}
        values={deviationEntries}
        highlighted={highlightedDeviationEntry}
      />
    </div>
  );
}
