import {
  CogniteEvent,
  CogniteInternalId,
  InternalId,
  TimeseriesFilterQuery,
  Metadata,
} from "@cognite/sdk";
import { ReactNode } from "react";
import { Dayjs } from "@properate/dayjs";
import { SidebarProps } from "@properate/ui";

export enum NoteLevel {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  UNKNOWN = "unknown",
}

export const NoteLevelColors = {
  [NoteLevel.INFO]: "border-info bg-info-bg text-info-fg",
  [NoteLevel.WARNING]: "border-warning bg-warning-bg text-warning-fg",
  [NoteLevel.ERROR]: "border-error bg-error-bg text-error-fg",
  [NoteLevel.UNKNOWN]: "border-slate-400 bg-slate-100 text-slate-600",
};

export enum NoteSource {
  WEB_ENERGY = "web_energy",
  WEB_ANALYSIS = "web_analysis",
  WEB_ALARMS = "web_alarms",
  WEB_ORG_ALARMS = "web_org_alarms",
  WEB_COMPONENTS = "web_components",
  WEB_INDOOR_CLIMATE = "web_indoor_climate",
  WEB_SCHEMA_VIEW = "web_schema_view",
  WEB_FLOOR_PLAN = "web_floor_plan",
  WEB_CLOUD_AUTOMATIONS = "web_cloud_automations",
  WEB_VIRTUAL_SENSORS = "web_virtual_sensors",
  WEB_SET_POINTS = "web_set_points",
  WEB_WASTE = "web_waste",
  WEB_INCIDENTS = "web_incidents",
  UNKNOWN = "unknown",
}

export type Note = {
  id: number;
  dataSetId: number;
  content: string;
  startTime: number;
  endTime: number;
  assetIds: number[];
  level: NoteLevel;
  source: NoteSource;
  createdBy: string;
  createdTime: Date;
  lastUpdatedBy: string;
  lastUpdatedTime: Date;
  sourceId?: string;
};
export type CreateNote = Omit<
  Note,
  "createdBy" | "lastUpdatedBy" | "createdTime" | "lastUpdatedTime" | "id"
>;
export type EditNote = CreateNote & { id: number };

export type PostNoteParams =
  | { note: CreateNote; create: true }
  | { note: EditNote; create: false };

export type CogniteEventNoteMetadata = Metadata & {
  created_by: string;
  content: string;
  last_updated_by: string;
  level: NoteLevel;
  sourceId?: string;
};

export type CogniteEventNote = Omit<CogniteEvent, "metadata"> & {
  metadata: CogniteEventNoteMetadata;
};

export enum NotesAssetFilterMode {
  TimeseriesQuery,
  TimeseriesList,
  AssetList,
}

type TimeseriesQueryFilterProps = {
  assetFilterMode: NotesAssetFilterMode.TimeseriesQuery;
  filterQuery: TimeseriesFilterQuery;
  idSet?: Set<number>;
};

type TimeseriesListFilterProps = {
  assetFilterMode: NotesAssetFilterMode.TimeseriesList;
  filterQuery?: TimeseriesFilterQuery;
  idSet: Set<number>;
};
type AssetListFilterProps = {
  assetFilterMode: NotesAssetFilterMode.AssetList;
  filterQuery?: TimeseriesFilterQuery;
  idSet: Set<number>;
};

export type AssetFilterMode =
  | TimeseriesQueryFilterProps
  | TimeseriesListFilterProps
  | AssetListFilterProps;

export type NotesSidebarProps = Pick<
  NotesSidebarValues,
  | "colorOverrides"
  | "noteSource"
  | "buildings"
  | "startTime"
  | "endTime"
  | "customContent"
> &
  SidebarProps &
  AssetFilterMode;

export type NotesAccordionProps = Pick<NotesSidebarProps, "colorOverrides">;

export enum NoteMetaField {
  Timeseries,
  Level,
  Changelog,
  Source,
  Building,
}

export type NoteMetaFieldOptions = {
  truncateTimeseries?: boolean;
};

export type NoteMetaProps = NotesAccordionProps & {
  note: Partial<Note>;
  showFields?: NoteMetaField[];
  hideFields?: NoteMetaField[];
  fieldOptions?: NoteMetaFieldOptions;
};

export type NoteMetaEntryProps = {
  label: ReactNode;
  content: ReactNode;
  className?: string;
  labelClassName?: string;
  contentClassName?: string;
};

export enum NotesSidebarViewState {
  list = "list",
  create = "create",
  edit = "edit",
  customContent = "customContent",
}

export type NoteBuilding = InternalId & {
  name: string;
};

export type NotesSidebarValues = AssetFilterMode & {
  buildings: NoteBuilding[];
  viewState: NotesSidebarViewState;
  note: Partial<Note>;
  noteSource: NoteSource;
  colorOverrides?: Record<CogniteInternalId, string>;
  customContent?: ReactNode;
  startTime?: Date;
  endTime?: Date;
};

export type NoteFormValues = {
  content: string;
  dateRange: [Dayjs, Dayjs];
  level: NoteLevel;
  assetIds: { label: string; value: string }[] | string[];
};

export type SelectedNodeForPrefilledNote = {
  timestamp: number;
  assetIds?: number[];
  dataSetId?: number;
};
