import useSWR from "swr";
import { formatMeasurement } from "@properate/common";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { convertUnit } from "@/pages/common/utils";
import { useFloorPlanPinConfig } from "../FloorPlanMapPinConfigContext";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";

export function useFloorPlanPinTimeseriesLatestValue() {
  const timeseriesId = useFloorPlanPinTimeseriesId();
  const cogniteClient = useProperateCogniteClient();

  const values = useSWR(
    timeseriesId ? ["timeseries-latest-value", timeseriesId] : null,
    ([_, timeseriesId]) => cogniteClient.getLatestValue(timeseriesId),
  );

  const config = useFloorPlanPinConfig(values.data?.externalId);

  const unit = config?.unit ?? values.data?.unit;
  const latestDatapoint = values.data?.datapoints?.[0];

  const converted = convertUnit(
    values.data?.unit,
    unit,
    latestDatapoint?.value as number | undefined,
  );

  const formattedValue =
    converted.value !== undefined
      ? formatMeasurement({ value: converted.value, unit: converted.unit })
      : "-";

  return {
    unit,
    latestDatapoint,
    formattedValue,
    isLoading: values.isLoading,
    error: values.error,
  };
}
