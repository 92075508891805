import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Switch,
} from "antd";
import { Timeseries } from "@cognite/sdk";
import { DERIVED_UNITS, formatUnit } from "@properate/common";
import { ClipboardButton } from "@properate/ui";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useSensorCategoryOptions } from "@/pages/common/SchemaView/TechnicalSchema/sensorQueries";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { AuditLogModal } from "@/components/AuditLog";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { useFloorPlanPinEnsuringTimeseriesId } from "./FloorPlanMapPinContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanPinTimeseries } from "./hooks/useFloorPlanPinTimeseries";
import { useFloorPlanPinStatus } from "./hooks/useFloorPlanPinStatus";
import { useFloorPlanPinStatusUpdate } from "./hooks/useFloorPlanPinStatusUpdate";

export function FloorPlanMapPinSetPointStrategy(props: PropsWithChildren) {
  const [isEditing] = useFloorPlanEditMode();
  const pin = useFloorPlanPinEnsuringTimeseriesId();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showTimeseriesModal, setShowTimeseriesModal] = useState(false);
  const floorPlanUpdate = useFloorPlanUpdate();

  async function handleSwapTimeseries(timeseriesList: Timeseries[]) {
    const [timeseries] = timeseriesList;
    const newTimeseriesId = timeseries.id;

    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
        insert: [{ ...pin, timeseriesId: newTimeseriesId }],
      },
    });
  }

  const categoryOptions = useSensorCategoryOptions();

  return (
    <Fragment>
      {isEditing ? (
        <div className="flex">
          {props.children}
          <button
            onClick={() => setShowSettingsModal(true)}
            className="flex items-center mr-4 bg-transparent border-none cursor-pointer"
          >
            <EditOutlined />
          </button>
        </div>
      ) : (
        <div
          role="button"
          tabIndex={0}
          onClick={() => setShowSettingsModal(true)}
          className="flex cursor-pointer"
        >
          {props.children}
        </div>
      )}
      <TimeseriesSelectionModal
        open={showTimeseriesModal}
        onHide={() => setShowTimeseriesModal(false)}
        selectedIds={[pin.timeseriesId]}
        categoryOptions={categoryOptions}
        hiddenFilters={["building"]}
        onOk={handleSwapTimeseries}
        max={1}
      />
      <SetPointSettingsModal
        open={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
        onRequestTimeseriesSelection={() => setShowTimeseriesModal(true)}
      />
    </Fragment>
  );
}

type SetPointSettingsModalProps = {
  open: boolean;
  onHide: () => void;
  onRequestTimeseriesSelection: () => void;
};

function SetPointSettingsModal(props: SetPointSettingsModalProps) {
  type FormFields = {
    unit: string;
    priority: number;
    value: number;
  };

  const t = useTranslations();
  const timeseries = useFloorPlanPinTimeseries();
  const pin = useFloorPlanPinEnsuringTimeseriesId();
  const [showHistory, setShowHistory] = useState(false);
  const [form] = Form.useForm<FormFields>();
  const floorPlanUpdate = useFloorPlanUpdate();
  const buildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(buildingId);
  const status = useFloorPlanPinStatus();
  const statusUpdate = useFloorPlanPinStatusUpdate();
  const setFieldValue = form.setFieldValue;

  useEffect(() => {
    setFieldValue("value", status.data?.value);
  }, [setFieldValue, status.data?.value]);

  async function handleRemove() {
    try {
      await floorPlanUpdate.trigger({
        pins: {
          remove: pin,
        },
      });

      props.onHide();
    } finally {
      // noop
    }
  }

  const submit = (arg: FormFields) => {
    statusUpdate.trigger(arg, {
      onSuccess() {
        props.onHide();
      },
    });
  };

  return (
    <Modal
      open={props.open}
      onCancel={props.onHide}
      width="520px"
      title={t("floor-plan-v2.sensor-settings-modal-title")}
      footer={
        <div className="flex gap-2">
          <Button
            icon={<EditOutlined />}
            onClick={props.onRequestTimeseriesSelection}
            disabled={statusUpdate.isMutating}
          />
          <Button
            icon={<HistoryOutlined />}
            onClick={() => setShowHistory(true)}
            disabled={statusUpdate.isMutating}
          />
          <Popconfirm
            title={t("floor-plan-v2.sensor-settings-modal-delete-pop-title")}
            onConfirm={handleRemove}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              disabled={statusUpdate.isMutating}
            />
          </Popconfirm>
          <div className="flex-1" />
          <Button onClick={props.onHide} disabled={statusUpdate.isMutating}>
            {t("floor-plan-v2.sensor-settings-modal-cancel")}
          </Button>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={statusUpdate.isMutating}
          >
            {t("floor-plan-v2.sensor-settings-modal-save")}
          </Button>
        </div>
      }
    >
      <Form<FormFields>
        form={form}
        layout="vertical"
        initialValues={{
          unit:
            (timeseries.data &&
              overrideUnits &&
              overrideUnits[timeseries.data.externalId!]?.unit) ||
            timeseries.data?.unit,
          priority: status.data?.priority,
          value: status.data?.value,
        }}
        onFinish={submit}
      >
        <div className="mb-6">
          <div className="flex items-center h-8 gap-2">
            {t("floor-plan-v2.sensor-settings-modal-field-name")}:
            <div className="flex-1 truncate">
              {timeseries.data
                ? formatExtendedTimeseriesDescription(timeseries.data)
                : t("ui.loading")}
            </div>
            {timeseries.data && (
              <ClipboardButton
                text={formatExtendedTimeseriesDescription(timeseries.data)}
              />
            )}
          </div>
          <div className="flex items-center h-8 gap-2">
            {t("floor-plan-v2.sensor-settings-modal-field-id")}:
            <div className="flex-1 truncate">{timeseries.data?.externalId}</div>
            {timeseries.data?.externalId && (
              <ClipboardButton text={timeseries.data?.externalId} />
            )}
          </div>
          {timeseries.data?.metadata?.min_value ||
          timeseries.data?.metadata?.max_value ? (
            <div className="flex gap-6">
              <div className="flex items-center h-8 gap-2">
                {t("floor-plan-v2.sensor-settings-modal-field-min-value")}:
                <div>{timeseries.data.metadata.min_value ?? "-"}</div>
              </div>
              <div className="flex items-center h-8 gap-2">
                {t("floor-plan-v2.sensor-settings-modal-field-max-value")}:
                <div>{timeseries.data.metadata.max_value ?? "-"}</div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex gap-6">
          <Form.Item<FormFields>
            label={t("floor-plan-v2.sensor-settings-modal-field-unit")}
            name="unit"
          >
            <Radio.Group value={timeseries.data?.unit} buttonStyle="solid">
              {timeseries.data?.unit && (
                <Radio.Button value={timeseries.data.unit}>
                  {formatUnit(timeseries.data.unit)}
                </Radio.Button>
              )}
              {timeseries.data?.unit &&
                DERIVED_UNITS[timeseries.data.unit] &&
                Object.keys(DERIVED_UNITS[timeseries.data.unit]).map((unit) => (
                  <Radio.Button value={unit} key={unit}>
                    {formatUnit(unit)}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </Form.Item>
          <div className="flex-1">
            <Form.Item<FormFields>
              label={t("floor-plan-v2.sensor-settings-modal-field-priority")}
              name="priority"
            >
              <Select loading={status.isLoading}>
                <Select.Option value={16}>16</Select.Option>
                <Select.Option value={8}>8</Select.Option>
                {status.data &&
                status.data.priority !== 8 &&
                status.data.priority !== 16 ? (
                  <Select.Option value={status.data.priority}>
                    {status.data.priority}
                  </Select.Option>
                ) : null}
              </Select>
            </Form.Item>
          </div>
          <div className="flex-1">
            <Form.Item<FormFields>
              label={t("floor-plan-v2.sensor-settings-modal-field-value")}
              name="value"
            >
              <InputNumber
                className="w-full"
                min={
                  typeof timeseries.data?.metadata?.min_value === "string"
                    ? parseInt(timeseries.data.metadata.min_value)
                    : undefined
                }
                max={
                  typeof timeseries.data?.metadata?.max_value === "string"
                    ? parseInt(timeseries.data.metadata.max_value)
                    : undefined
                }
              />
            </Form.Item>
          </div>
        </div>
        <label className="flex items-center gap-2 pb-4">
          <Switch
            checked={pin.variant === "value+name"}
            onChange={(checked) => {
              floorPlanUpdate.trigger({
                pins: {
                  remove: pin,
                  insert: [
                    {
                      ...pin,
                      variant: checked ? "value+name" : "value",
                    },
                  ],
                },
              });
            }}
          />
          <span>{t("floor-plan-v2.show-set-point-name")}</span>
        </label>
      </Form>
      {showHistory && pin.externalId && timeseries.data && (
        <AuditLogModal
          name={formatExtendedTimeseriesDescription(timeseries.data)}
          externalId={pin.externalId}
          onHide={() => setShowHistory(false)}
        />
      )}
    </Modal>
  );
}
