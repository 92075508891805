import { AlarmRule } from "@properate/common";
import { ReactNode } from "react";
import { getAlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage";
import { AlarmDetailsTimespanFormDefaults } from "@/pages/alarms/details/components/AlarmDetailsTimespanSelector";
import { AlarmDetailsNavigatorFormDefaults } from "@/pages/alarms/details/components/AlarmTimeseriesGraph/AlarmTimeseriesNavigator";
import { FormContextProvider } from "@/pages/alarms/details/FormContext";

type Props = {
  alarmRule?: AlarmRule;
  children: ReactNode;
};

export const IncidentAlarmContextWrapper = ({ alarmRule, children }: Props) => {
  if (!alarmRule) return children;

  return (
    <FormContextProvider
      entries={{
        ...getAlarmSettingsFormFields(alarmRule),
        ...AlarmDetailsTimespanFormDefaults,
        ...AlarmDetailsNavigatorFormDefaults,
      }}
      onSubmit={() => {
        // noop
      }}
      className="flex gap-4 flex-col flex-1 h-full"
    >
      {children}
    </FormContextProvider>
  );
};
