import useSWR from "swr";
import { useMemo } from "react";
import { getGroup } from "@/eepApi";
import { useHandleApiError } from "@/utils/api";

export const useHasNextPerson = ({
  alertedGroupId,
  currentAcknowledgedUserEmail,
}: {
  alertedGroupId?: string;
  currentAcknowledgedUserEmail?: string;
}) => {
  const handleAPIError = useHandleApiError();

  const {
    data: responsibleMembers = [],
    isLoading: isLoadingMembers,
    error: errorLoadingMembers,
  } = useSWR(["alertedGroup", alertedGroupId], () => {
    return alertedGroupId
      ? getGroup(alertedGroupId).then((group) => group.members)
      : [];
  });

  const currentResponsiblePersonIndex = responsibleMembers.findIndex(
    (person) => {
      return person.properate_user_email === currentAcknowledgedUserEmail;
    },
  );

  if (errorLoadingMembers) {
    handleAPIError(errorLoadingMembers);
  }

  return useMemo(
    () => ({
      hasNextPerson:
        responsibleMembers.length > 1 &&
        currentResponsiblePersonIndex < responsibleMembers.length - 1,
      isLoading: isLoadingMembers,
    }),
    [currentResponsiblePersonIndex, responsibleMembers, isLoadingMembers],
  );
};
