import { useUser } from "@properate/auth";
import { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { App, Button, Space, Modal, Tooltip, Input, Popover } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  PlusOutlined,
  LineChartOutlined,
  WarningTwoTone,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import { Node } from "reactflow";
import {
  sortString,
  ClipboardButton,
  ToggleSidebarButton,
} from "@properate/ui";
import { DoubleDatapoint, Timeseries } from "@cognite/sdk";
import { chunk, formatMeasurement, TimeSpan } from "@properate/common";
import { ExternalId } from "@cognite/sdk-core/dist/src";
import { useTranslations } from "@properate/translations";
import { PRIMARY } from "@/utils/ProperateColors";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { CalculationFlowNotesColumn } from "@/features/calculationFlow";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { AuditLogModal } from "@/components/AuditLog";
import { isErrorWithMessage } from "@/utils/api";
import {
  getCalculationFlows,
  deleteCalculationFlow,
  runCalculationFlow,
} from "../../../eepApi";
import { CompactContent } from "../../../components/CompactContent";
import { CalculationFlow, GraphModalData } from "../types";
import { GraphModal } from "../../common/SchemaGraph/GraphModal";
import RunModal from "./RunModal";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

function getOutputNodes(nodes: Node[]) {
  return nodes.filter((node) => node.data.operationId.startsWith("write_"));
}

function getOutputExternalIds(calculationFlow: CalculationFlow): string[] {
  const outputNodes = getOutputNodes(calculationFlow.calculation_flow.nodes);
  return outputNodes
    .map((node) =>
      node.data.externalIds
        ? node.data.externalIds.map(
            (externalId: ExternalId) => externalId.externalId,
          )
        : node.data.externalId?.externalId,
    )
    .flat()
    .filter((externalId) => !!externalId) as string[];
}

function findAssetIds(
  externalIds: string[],
  timerseries: Timeseries[],
): number[] {
  return externalIds
    .map((id) => {
      const ts = timerseries.find((ts) => ts.externalId === id);
      return ts?.assetId ? ts.assetId : undefined;
    })
    .filter((assetId) => assetId !== undefined) as number[];
}

interface Props {
  flowType: "cloudAutomation" | "virtualSensor";
}

export function CalculationFlowTable({ flowType }: Props) {
  const { notification } = App.useApp();
  const t = useTranslations();

  useBuildingPageTitle(
    flowType === "cloudAutomation"
      ? t("calculation-flow.cloud-automation")
      : t("calculation-flow.virtual-sensors"),
  );
  const { height: windowHeight } = useWindowSize();
  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { client } = useCogniteClient();
  const params = useParams();

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [flows, setFlows] = useState<CalculationFlow[]>([]);
  const [valueMap, setValueMap] = useState<
    Record<string, { value: number | string; timestamp?: Date; unit?: string }>
  >({});
  const [filteredFlows, setFilteredFlows] = useState<CalculationFlow[]>([]);
  const [deleteModalData, setDeleteModalData] =
    useState<CalculationFlow | null>(null);
  const [runModalData, setRunModalData] = useState<CalculationFlow | null>(
    null,
  );
  const [showTimeseriesData, setShowTimeseriesData] =
    useState<GraphModalData | null>(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [timeseriesList, setTimeseriesList] = useState<Timeseries[]>([]);
  const [
    showAuditLogForSelectedTimeseries,
    setShowAuditLogForSelectedTimeseries,
  ] = useState<Timeseries[]>([]);
  const user = useUser();

  useEffect(() => {
    async function fetchAvailableFlows() {
      setLoading(true);
      try {
        if (currentBuildingId) {
          setFlows([]);
          const flows = await getCalculationFlows(
            Number(currentBuildingId),
            flowType,
          );
          const outputExternalIds = [
            ...new Set(flows.map((flow) => getOutputExternalIds(flow)).flat()),
          ];

          Promise.all(
            chunk(outputExternalIds, 100).map((chunk) => {
              return client.timeseries.retrieve(
                chunk.map((externalId) => ({
                  externalId: externalId!,
                })),
                { ignoreUnknownIds: true },
              );
            }),
          ).then((values) => {
            setTimeseriesList(values.flat());
          });

          setFlows(flows);
        }
      } catch (error) {
        if (isErrorWithMessage(error)) {
          return notification.error({
            message: error.message,
          });
        }
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchAvailableFlows();
  }, [
    currentBuildingId,
    notification,
    flowType,
    params,
    setFlows,
    client.timeseries,
    t,
  ]);

  useEffect(() => {
    if (flows.length > 0) {
      const outputExternalIds = [
        ...new Set(flows.map((flow) => getOutputExternalIds(flow)).flat()),
      ];

      if (outputExternalIds.length === 0) return;

      Promise.all(
        chunk(outputExternalIds, 100).map((chunk) => {
          return client.datapoints.retrieveLatest(
            chunk.map((externalId) => ({
              externalId: externalId!,
            })),
            { ignoreUnknownIds: true },
          );
        }),
      ).then((values) => {
        const newValues: Record<
          string,
          { value: number; timestamp: Date; unit?: string }
        > = {};
        values.flat().forEach((dp) => {
          const datapoint = dp.datapoints?.[0] as DoubleDatapoint;
          newValues[dp.externalId!] = {
            unit: dp.unit,
            value: datapoint?.value,
            timestamp: datapoint?.timestamp,
          };
        });
        setValueMap(newValues);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flows, setValueMap, flowType]);

  const handleDeleteOk = async () => {
    setDeleting(true);
    if (deleteModalData !== null && deleteModalData?.id !== null) {
      try {
        await deleteCalculationFlow(deleteModalData.id);
        setDeleting(false);
        notification.success({
          message: t("calculation-flow.virtual-sensor-was-removed"),
        });
      } catch {
        notification.error({
          message: t("calculation-flow.cant-remove-virtual-sensor"),
        });
      }
    }
    setFlows(flows.filter((flow) => flow !== deleteModalData));
    setDeleteModalData(null);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  const searchWords = useMemo(
    () => query.split(" ").filter((word) => word !== ""),
    [query],
  );

  useEffect(() => {
    if (searchWords.length === 0) setFilteredFlows(flows);
    else {
      setFilteredFlows(
        flows.filter((flow) => {
          return searchWords.some(
            (query) =>
              flow.name.toLowerCase().includes(query.toLowerCase()) ||
              flow.description.toLowerCase().includes(query.toLowerCase()) ||
              getOutputExternalIds(flow).some(
                (externalId) =>
                  externalId?.toLowerCase().includes(query.toLowerCase()) ||
                  (flow.user !== null &&
                    flow.user.toLowerCase().includes(query.toLowerCase())),
              ),
          );
        }),
      );
    }
    // disable exhaustive-deps because we want to ignore getOutputExternalId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flows, searchWords]);

  const renderAuditLog = (timeseries: Timeseries[] = []) => {
    const handleClick = (ts: Timeseries[]) => () =>
      setShowAuditLogForSelectedTimeseries(ts);

    if (!timeseries || timeseries.length === 0) {
      return (
        <Tooltip title={t("calculation-flow.no-setpoints-found")}>
          <Button
            disabled
            icon={<HistoryOutlined />}
            onClick={handleClick(timeseries)}
          />
        </Tooltip>
      );
    }

    if (timeseries.length === 1) {
      return (
        <Button icon={<HistoryOutlined />} onClick={handleClick(timeseries)} />
      );
    }

    return (
      <Popover
        content={
          <Space direction="vertical">
            <ul>
              {timeseries.map((ts) => (
                <div className="flex" key={ts.id}>
                  <Button type="link" onClick={handleClick([ts])}>
                    <li key={ts.externalId}>
                      {formatExtendedTimeseriesDescription(ts)}
                    </li>
                  </Button>
                </div>
              ))}
            </ul>
          </Space>
        }
        trigger="hover"
      >
        <Button icon={<HistoryOutlined />} type="link" />
      </Popover>
    );
  };
  return (
    <>
      <PageHeader
        title={
          flowType === "virtualSensor"
            ? t("calculation-flow.virtual-sensors")
            : t("calculation-flow.cloud-automation")
        }
        extra={
          <Space direction="horizontal">
            <Input
              placeholder={t("calculation-flow.search-placeholder")}
              onChange={handleSearch}
              allowClear
            />
            <Button
              data-testid="new-button"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("new")}
              disabled={user.isViewer}
            >
              {t("calculation-flow.new")}
            </Button>
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </Space>
        }
      />
      <CompactContent>
        <TableWithoutDefaultSort
          pagination={false}
          dataSource={filteredFlows}
          rowKey="id"
          scroll={{
            y:
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "100%",
          }}
          columns={[
            {
              title: t("calculation-flow.table.name"),
              width: 200,
              ...(flowType === "cloudAutomation"
                ? {
                    defaultSortOrder: "ascend",
                  }
                : null),
              sorter: ({ name: nameOne }, { name: nameTwo }) =>
                sortString(nameOne, nameTwo),
              render: (_, { name }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={name}
                  title={name}
                />
              ),
            },
            {
              title: t("calculation-flow.table.description"),
              width: 200,
              sorter: (
                { description: descriptionOne },
                { description: descriptionTwo },
              ) => sortString(descriptionOne, descriptionTwo),
              render: (_, { description }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={description}
                  title={description}
                />
              ),
            },
            ...(flowType === "virtualSensor"
              ? [
                  {
                    title: t("calculation-flow.table.timeseries"),
                    defaultSortOrder: "ascend" as const,
                    width: 520,
                    sorter: (
                      { id: idOne }: CalculationFlow,
                      { id: idTwo }: CalculationFlow,
                    ) => sortString(idOne, idTwo),
                    render: (_: string, calculationFlow: CalculationFlow) => {
                      const outputExternalIds =
                        getOutputExternalIds(calculationFlow);
                      if (outputExternalIds.length === 0) return <></>;
                      return (
                        <>
                          {outputExternalIds.map((outputExternalId) => (
                            <>
                              <ProperateHighlighter
                                searchWords={searchWords}
                                textToHighlight={outputExternalId}
                                title={outputExternalId}
                              />
                              <ClipboardButton text={outputExternalId} />
                            </>
                          ))}
                        </>
                      );
                    },
                  },
                ]
              : []),
            {
              title: t("calculation-flow.table.status"),
              key: "status",
              align: "center",
              width: 50,
              sorter: (
                { notifications: notificationsOne, frequency: frequencyOne },
                { notifications: notificationsTwo, frequency: frequencyTwo },
              ) => {
                const hasNotificationsOne = notificationsOne.length > 0;
                const hasNotificationsTwo = notificationsTwo.length > 0;
                const hasFrequencyOne = frequencyOne > 0;
                const hasFrequencyTwo = frequencyTwo > 0;
                if (hasNotificationsOne) {
                  if (hasNotificationsTwo) {
                    if (hasFrequencyOne) {
                      return hasFrequencyTwo ? 0 : -1;
                    }
                    return hasFrequencyTwo ? 1 : 0;
                  }
                  return -1;
                }
                if (hasNotificationsTwo) {
                  return 1;
                }
                if (hasFrequencyOne) {
                  return hasFrequencyTwo ? 0 : -1;
                }
                return hasFrequencyTwo ? 1 : 0;
              },
              render: (_, { notifications, frequency, last_run }) => {
                if (notifications.length > 0) {
                  return (
                    <Tooltip
                      title={notifications
                        .map((notification) => notification.message)
                        .join(", ")}
                    >
                      <WarningTwoTone twoToneColor="#f56a00" />
                    </Tooltip>
                  );
                }
                if (frequency > 0) {
                  return (
                    <Tooltip
                      title={
                        last_run === null
                          ? t("calculation-flow.table.never-calculated")
                          : t("calculation-flow.table.last-calculation", {
                              days: dayjs
                                .duration(dayjs().diff(dayjs(last_run)))
                                .humanize(),
                            })
                      }
                    >
                      <div
                        style={{
                          color: PRIMARY,
                          fontSize: "2.5em",
                        }}
                      >
                        &bull;
                      </div>
                    </Tooltip>
                  );
                }
                return null;
              },
            },
            {
              title:
                flowType === "cloudAutomation"
                  ? t("calculation-flow.table.last-values")
                  : t("calculation-flow.table.last-value"),
              key: "value",
              align: "right" as const,
              width: 100,
              sorter: (
                calculationFlowOne: CalculationFlow,
                calculationFlowTwo: CalculationFlow,
              ) => {
                if (
                  (calculationFlowOne.notifications.length > 0 ||
                    calculationFlowOne.frequency <= 0) &&
                  (calculationFlowTwo.notifications.length > 0 ||
                    calculationFlowTwo.frequency <= 0)
                ) {
                  return 0;
                }
                if (
                  calculationFlowOne.notifications.length > 0 ||
                  calculationFlowOne.frequency <= 0
                ) {
                  return -1;
                }
                if (
                  calculationFlowTwo.notifications.length > 0 ||
                  calculationFlowTwo.frequency <= 0
                ) {
                  return 1;
                }

                const valueOneId = getOutputExternalIds(calculationFlowOne)[0];
                const valueTwoId = getOutputExternalIds(calculationFlowTwo)[0];

                if (!valueOneId && !valueTwoId) {
                  return 0;
                }
                if (!valueOneId) {
                  return -1;
                }
                if (!valueTwoId) {
                  return 1;
                }

                const valueOne = valueMap[valueOneId];
                const valueTwo = valueMap[valueTwoId];

                if (valueOne === valueTwo) {
                  return 0;
                }
                if (valueOne === undefined) {
                  return -1;
                }
                if (!valueTwo === undefined) {
                  return 1;
                }

                if (
                  typeof valueOne.value === "string" &&
                  typeof valueTwo.value === "string"
                ) {
                  return valueOne.value.localeCompare(valueTwo.value);
                }

                if (typeof valueOne.value === "string") {
                  return -1;
                }
                if (typeof valueTwo.value === "string") {
                  return 1;
                }

                return valueOne.value - valueTwo.value;
              },
              render: (_: string, calculationFlow: CalculationFlow) => {
                if (
                  calculationFlow.notifications.length > 0 ||
                  calculationFlow.frequency <= 0
                ) {
                  return "--";
                }
                const externalIds = getOutputExternalIds(calculationFlow);
                if (externalIds.length === 0) return <></>;

                return externalIds
                  .map((externalId) => {
                    const value = valueMap[externalId];
                    if (typeof value?.value === "string") {
                      return value.value;
                    }
                    return valueMap[externalId]
                      ? formatMeasurement({
                          value: valueMap[externalId].value as
                            | number
                            | undefined,
                          unit: valueMap[externalId].unit,
                        })
                      : "--";
                  })
                  .join("  ");
              },
            },
            ...(flowType === "virtualSensor"
              ? [
                  {
                    title: t("calculation-flow.table.unit"),
                    key: "unit",
                    width: 50,
                    align: "right" as const,
                    sorter: (
                      {
                        calculation_flow: { nodes: nodesOne },
                      }: CalculationFlow,
                      {
                        calculation_flow: { nodes: nodesTwo },
                      }: CalculationFlow,
                    ) => {
                      const unitOne = getOutputNodes(nodesOne)?.[0]?.data.unit;
                      const unitTwo = getOutputNodes(nodesTwo)?.[0]?.data.unit;
                      return sortString(unitOne, unitTwo);
                    },
                    render: (
                      _: string,
                      { calculation_flow: { nodes } }: CalculationFlow,
                    ) => {
                      return getOutputNodes(nodes)?.[0]?.data.unit?.replace(
                        "no-units",
                        "",
                      );
                    },
                  },
                ]
              : []),
            {
              title: t("calculation-flow.table.created-by"),
              sorter: ({ user: userOne }, { user: userTwo }) =>
                sortString(userOne, userTwo),
              dataIndex: "user",
              width: 170,
              render: (_, { user }) =>
                user && (
                  <ProperateHighlighter
                    searchWords={searchWords}
                    textToHighlight={user}
                  />
                ),
            },
            {
              key: "actions",
              title: t("calculation-flow.table.actions"),
              width: 200,
              render: (_, calculationFlow) => {
                const externalIds =
                  flowType === "virtualSensor"
                    ? [getOutputExternalIds(calculationFlow)[0]]
                    : getOutputExternalIds(calculationFlow);
                const writableTSListForEachCloudAutomationAndVirtualSensors =
                  timeseriesList.filter((ts) =>
                    externalIds.includes(ts.externalId!),
                  );
                const assetIds = findAssetIds(externalIds, timeseriesList);
                return (
                  <Space>
                    {flowType === "virtualSensor" && (
                      <Button
                        icon={<LineChartOutlined />}
                        onClick={async (event) => {
                          event.stopPropagation();
                          const outputExternalId =
                            getOutputExternalIds(calculationFlow)[0];
                          if (outputExternalId) {
                            const ts = await client.timeseries.retrieve([
                              {
                                externalId: outputExternalId,
                              },
                            ]);
                            if (ts.length > 0)
                              setShowTimeseriesData({ id: ts[0].id, unit: "" });
                          }
                        }}
                      />
                    )}

                    <Button
                      icon={<EditOutlined />}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (calculationFlow.id !== null) {
                          navigate(encodeURIComponent(calculationFlow.id));
                        }
                      }}
                    />
                    {renderAuditLog(
                      writableTSListForEachCloudAutomationAndVirtualSensors,
                    )}
                    {assetIds.length > 0 && (
                      <CalculationFlowNotesColumn ids={assetIds} />
                    )}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      disabled={user.isViewer}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteModalData(calculationFlow);
                      }}
                    />
                    {flowType === "virtualSensor" && (
                      <Button
                        icon={<PlayCircleOutlined />}
                        disabled={user.isViewer}
                        onClick={(event) => {
                          event.stopPropagation();
                          setRunModalData(calculationFlow);
                        }}
                      />
                    )}
                  </Space>
                );
              },
            },
          ]}
          loading={loading}
        />
        {building.dataSetId && (
          <NotesSidebar
            noteSource={
              flowType === "virtualSensor"
                ? NoteSource.WEB_VIRTUAL_SENSORS
                : NoteSource.WEB_CLOUD_AUTOMATIONS
            }
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={
              new Set(
                timeseriesList
                  .filter((ts) => ts.assetId !== null)
                  .map((ts) => ts.assetId as number),
              )
            }
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
        {showAuditLogForSelectedTimeseries.length > 0 && (
          <AuditLogModal
            name={formatExtendedTimeseriesDescription(
              showAuditLogForSelectedTimeseries[0],
            )}
            externalId={showAuditLogForSelectedTimeseries[0].externalId!}
            onHide={() => setShowAuditLogForSelectedTimeseries([])}
          />
        )}
      </CompactContent>
      {deleteModalData && (
        <Modal
          title={t("calculation-flow.table.are-you-sure")}
          open
          onCancel={() => setDeleteModalData(null)}
          footer={[
            <Button key="cancel" onClick={() => setDeleteModalData(null)}>
              {t("calculation-flow.table.cancel")}
            </Button>,
            <Button
              key="delete"
              type="primary"
              loading={deleting}
              onClick={handleDeleteOk}
            >
              {t("calculation-flow.table.delete")}
            </Button>,
          ]}
        >
          {t.rich("calculation-flow.table.delete-description", {
            b: (text) => <b>{text}</b>,
            name: deleteModalData?.name,
          })}
        </Modal>
      )}
      {runModalData !== null && (
        <RunModal
          open={runModalData !== null}
          onRun={async (timeSpan: TimeSpan, purgeDatapoints: boolean) => {
            if (runModalData.id !== null)
              await runCalculationFlow(
                runModalData.id,
                timeSpan,
                purgeDatapoints,
              );
            setRunModalData(null);
          }}
          onClose={() => {
            setRunModalData(null);
          }}
        />
      )}
      {showTimeseriesData && (
        <GraphModal
          timeseriesInfo={showTimeseriesData}
          setTimeseriesInfo={setShowTimeseriesData}
          hide={() => setShowTimeseriesData(null)}
          expanded
          editable
          showDocuments={false}
          buildingId={currentBuildingId}
        />
      )}
    </>
  );
}
