import useSWR from "swr";
import { useMemo } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlan } from "../FloorPlanContext";
import { useFloorPlans } from "./useFloorPlans";

export function useFloorPlanBackground() {
  const floorPlan = useFloorPlan();
  const { client: cogniteClient } = useCogniteClient();

  return useSWR(
    ["floor-plan-img", floorPlan.background.cogniteFileId],
    async ([_, fileId]) =>
      cogniteClient.files
        .getDownloadUrls([{ id: fileId }])
        .then(([fileLink]) => fileLink.downloadUrl),
    { revalidateOnFocus: false },
  );
}

export function useFloorPlanBackgrounds() {
  const floorPlans = useFloorPlans();
  const { client: cogniteClient } = useCogniteClient();

  const ids = useMemo(
    () =>
      floorPlans.data?.map((floorPlan) => ({
        snapshotId: floorPlan.snapshotId,
        cogniteFileId: floorPlan.background.cogniteFileId,
      })),
    [floorPlans.data],
  );

  return useSWR(
    ids ? ["floor-plan-backgrounds", ids] : null,
    async ([_, ids]) => {
      const files = await cogniteClient.files.getDownloadUrls(
        ids.map((id) => ({
          id: id.cogniteFileId,
        })),
      );

      const filesBySnapshotId: Record<string, string> = {};

      for (const file of files) {
        if ("id" in file) {
          for (const id of ids) {
            if (id.cogniteFileId === file.id) {
              filesBySnapshotId[id.snapshotId] = file.downloadUrl;
            }
          }
        } else {
          console.warn("Ignoring a Cognite file without an id.", file);
        }
      }

      return filesBySnapshotId;
    },
  );
}
