import { useTranslations } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { useMemo } from "react";
import { formatUnit } from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { PinAlarm } from "../types";
import { useFloorPlanPinTimeseriesLatestValue } from "./useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlanPinTimeseries } from "./useFloorPlanPinTimeseries";
import { useFloorPlanPinStatus } from "./useFloorPlanPinStatus";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";

type Alarm = {
  level: PinAlarm["level"];
  message: string;
};

/**
 * Get the alarms for a pin.
 *
 * @param timeseriesId The timeseries ID is passed in when the pin is a room.
 * @returns Set of alarm messages usable for display
 */
export function useFloorPlanPinAlarms() {
  const t = useTranslations();
  const pin = useFloorPlanPin();
  const timeseriesId = useFloorPlanPinTimeseriesId();
  const timeseries = useFloorPlanPinTimeseries();
  const status = useFloorPlanPinStatus();
  const { latestDatapoint, unit } = useFloorPlanPinTimeseriesLatestValue();

  return useMemo<Alarm[]>(() => {
    if (!latestDatapoint || !timeseries.data) {
      return [];
    }

    const compute = (alarm: PinAlarm | null) => {
      const alarms: Alarm[] = [];

      if (
        pin.type !== "set-point-general" &&
        typeof latestDatapoint.value === "number" &&
        dayjs(latestDatapoint.timestamp).isBefore(dayjs().subtract(2, "h"))
      ) {
        alarms.push({
          level: "warning",
          message: t("floor-plan.last-data-point-came-for", {
            value: dayjs(latestDatapoint.timestamp).fromNow(),
          }),
        });
      }

      if (
        pin.type !== "set-point-general" &&
        typeof latestDatapoint.value === "number" &&
        typeof alarm?.min === "number" &&
        latestDatapoint.value < alarm.min
      ) {
        const formattedUnit = formatUnit(unit) || "";

        alarms.push({
          level: alarm.level,
          message:
            alarm.minLabel ??
            t("floor-plan-v2.sensor-alarm-under", {
              value: alarm.min + formattedUnit,
            }),
        });
      }

      if (
        pin.type !== "set-point-general" &&
        typeof latestDatapoint.value === "number" &&
        typeof alarm?.max === "number" &&
        latestDatapoint.value > alarm.max
      ) {
        const formattedUnit = formatUnit(unit) || "";

        alarms.push({
          level: alarm.level,
          message:
            alarm.maxLabel ??
            t("floor-plan-v2.sensor-alarm-over", {
              value: alarm.max + formattedUnit,
            }),
        });
      }

      if (pin.type === "set-point-general" && status.data?.outOfService) {
        alarms.push({
          level: "warning",
          message: t("floor-plan-v2.sensor-alarm-out-of-service"),
        });
      }

      if (pin.type === "set-point-general" && status.data?.fault) {
        alarms.push({
          level: "warning",
          message: status.data.fault,
        });
      }

      return alarms;
    };

    if (pin.type === "room") {
      if (!timeseriesId || !pin.alarms?.[timeseriesId]) {
        return [];
      }

      return compute(pin.alarms[timeseriesId]);
    }

    return compute(pin.alarm);
  }, [
    timeseriesId,
    latestDatapoint,
    pin,
    t,
    timeseries.data,
    status.data,
    unit,
  ]);
}
