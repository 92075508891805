import {
  AlarmRuleTypeName,
  OffsetDirection,
  PartialAlarmRule,
} from "@properate/common";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { numberValidator } from "../FormContext/validators";
import { getSubmitValueEntry } from "../FormContext/utils";
import { requiredSelect, tKey } from "./common/utils";
import {
  TimeseriesThresholdAlarmMetaFields,
  TimeseriesThresholdFormFields,
  TimeseriesThresholdTypeSpecificSummary,
} from "./TimeseriesThreshold";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  getTypeSpecificOffsetAlarmRuleFields,
  OffsetMode,
  SelectOffsetType,
} from "./common/SelectOffset";
import {
  FixedTimeseriesName,
  FixedTimeseriesThresholdDelayOnly,
} from "./common/FixedTimeseriesThresholdDelayOnly";
import { AlarmRuleType } from "./index";

export const TimeseriesThresholdVOCHighDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.TimeseriesThresholdVOC,
  labelTranslationKey: tKey("timeseries-threshold.voc-high.type-label"),
  getFormFields: (alarmRule) => {
    const offsetFields = getSelectOffsetFieldDefinitions({
      alarmRule,
      mode: OffsetMode.Threshold,
    });
    offsetFields[SelectOffsetType.Direction].defaultValue =
      OffsetDirection.BaseGTCompare;

    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
      },
      [TimeseriesThresholdFormFields.SubBuilding]: {
        defaultValue: alarmRule?.sub_building_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.sub-building`),
              ).toLowerCase(),
            }),
          ),
      },
      ...offsetFields,
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      sub_building_id: getSubmitValueEntry<number>(
        entries,
        TimeseriesThresholdFormFields.SubBuilding,
      ),
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          ...getTypeSpecificOffsetAlarmRuleFields({ entries }),
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
        },
      },
    };
  },
  formComponent: (
    <FixedTimeseriesThresholdDelayOnly
      fixedTimeseries={FixedTimeseriesName.VOC}
    />
  ),
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
        DeviationTableColumn.Notes,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
      {...props}
    />
  ),
  availability: {
    subBuildingMustHaveNamedTimeseries: [FixedTimeseriesName.VOC],
  },
};
