import { AlarmRule } from "@properate/common";
import { Popover } from "antd";
import { useTranslations } from "@properate/translations";
import { NewAlarmEventsList } from "@/features/alarms/components/AlarmEventsList/NewAlarmEventsList";
import { AlarmEventsList } from "../AlarmEventsList/AlarmEventsList";
import { PopoverContent } from "./elements";

type Props = {
  alarm: AlarmRule;
};

export function NewAlarmsTableEventsColumn({ alarm }: Props) {
  const t = useTranslations();
  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <Popover
        placement="left"
        title={t("org-alarms.table-columns.events")}
        content={
          <PopoverContent width={400}>
            <NewAlarmEventsList alarm={alarm} />
          </PopoverContent>
        }
      >
        <span data-testid={`alarm-event-count-${alarm.alarm_id}`}>
          {alarm.alarm_event_count}
        </span>
      </Popover>
    </div>
  );
}
