import dayjs, { Dayjs } from "@properate/dayjs";
import {
  AlarmSeverity,
  getSystemCodeFromExternalId,
  SimplifiedAlarmPriority,
} from "@properate/common";
import { Asset } from "@cognite/sdk";
import { PropsWithChildren } from "react";
import { NoteLevel } from "./types";

export function dateFormat(date: number | Date, time = false) {
  if (time) {
    return dayjs(date).format("L LT");
  }
  return dayjs(date).format("L");
}

export function repeatUntilSuccessful<R, P, C>(
  func: (params: P) => Promise<R>,
  params: P,
  check: (result: R, params: C) => boolean,
  checkParams: C,
  maxAttempts = 15,
  timeoutMs = 300,
): Promise<{ result: R; success: true } | { result: null; success: false }> {
  return new Promise((resolve, reject) => {
    let attempt = 0;
    const interval = setInterval(async () => {
      attempt += 1;
      try {
        const result = await func(params);
        if (check(result, checkParams)) {
          clearInterval(interval);
          resolve({ result, success: true });
        }
      } catch (e) {
        clearInterval(interval);
        reject(e);
      }
      if (attempt >= maxAttempts) {
        clearInterval(interval);
        resolve({ result: null, success: false });
      }
    }, timeoutMs);
  });
}

export function getLabelForAsset(asset: Asset) {
  return `${getSystemCodeFromExternalId(asset.externalId!)} ${
    asset.description ?? ""
  } ${asset.name}`;
}

export function isAllDay({
  startTime,
  endTime,
}: {
  startTime: number | Date | Dayjs | undefined | null;
  endTime: number | Date | Dayjs | undefined | null;
}) {
  if (
    startTime === null ||
    startTime === undefined ||
    endTime === null ||
    endTime === undefined
  ) {
    return false;
  }
  const start = dayjs(startTime);
  const end = dayjs(endTime);
  return (
    start?.hour() === 0 &&
    start?.minute() === 0 &&
    start?.second() === 0 &&
    start?.millisecond() === 0 &&
    end?.hour() === 23 &&
    end?.minute() === 59 &&
    end?.second() === 59 &&
    end?.millisecond() === 999
  );
}

export function CenterContent({ children }: Readonly<PropsWithChildren>) {
  return (
    <div className={"h-full w-full flex flex-col items-center justify-center"}>
      {children}
    </div>
  );
}

export function getNoteLevelForAlarmSeverity(
  alarmSeverity: AlarmSeverity,
): NoteLevel {
  switch (alarmSeverity) {
    case "CRITICAL":
    case "ERROR":
      return NoteLevel.ERROR;
    case "WARNING":
      return NoteLevel.WARNING;
    case "INFO":
      return NoteLevel.INFO;
    default:
      return NoteLevel.UNKNOWN;
  }
}

export function getNoteLevelForAlarmPriority(priority: number): NoteLevel {
  if (priority >= 192) {
    return NoteLevel.INFO;
  }
  if (priority >= 128) {
    return NoteLevel.WARNING;
  }
  if (priority >= 64) {
    return NoteLevel.ERROR;
  }
  // Critical
  return NoteLevel.ERROR;
}

export const sortByLabel = <T extends { label: string }>(a: T, b: T) => {
  const aValue = a.label;
  const bValue = b.label;
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};
