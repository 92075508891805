import { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate, useParams } from "react-router-dom";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { ToggleSidebarButton } from "@properate/ui";
import { MessageOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { AlarmRule } from "@properate/common";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import {
  useGetFilteredNewAlarms,
  NewAlarmsTable,
  NewAlarmsTableFiltersProvider,
  NewAlarmsTableFilters,
} from "@/features/alarms";
import { useWindowSize } from "@/hooks/useWindowSize";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { CompactContent } from "@/components/CompactContent";
import { useHelp } from "@/context/HelpContext";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import CreateNewAlarmButton from "@/pages/alarms/details/components/CreateNewAlarmButton";
import AlarmGraph from "./AlarmGraph";

const GRAPH_HEIGHT = 400;
const GRAPH_TABLE_GAP = 20;
const ESTIMATED_TABLE_HEADER_HEIGHT = 29;

export function NewAlarms() {
  const t = useTranslations("alarm-list");
  useBuildingPageTitle(t("title"));
  const { id }: any = useParams();
  const { setHelp } = useHelp();
  const { dataSetId, name: currentBuildingName } = useCurrentBuilding();
  const navigate = useNavigate();

  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    setHelp({
      title: t("title"),
      content: t.rich("help-text", {
        p: (content) => <p>{content}</p>,
      }),
    });
  }, [setHelp]);

  const { filteredAlarms } = useGetFilteredNewAlarms(Number(id));
  const sidebarTimeseriesIds = filteredAlarms
    ?.map((alarm) => alarm.condition.type_specific.base_timeseries_id)
    .filter((id: number) => id) as number[];

  function goToAlarmDetail(alarm: AlarmRule) {
    navigate(`/asset/${id}/newAlarms/${alarm.alarm_id}`);
  }

  return (
    <NewAlarmsTableFiltersProvider>
      <PageHeader
        title={t("title")}
        extra={
          <>
            <NewAlarmsTableFilters />
            <CreateNewAlarmButton />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              icon={<MessageOutlined aria-hidden />}
              sidebarHiddenContent={t("show-notes-button")}
            />
          </>
        }
      />
      <CompactContent>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            height: "100%",
            gap: GRAPH_TABLE_GAP,
          }}
        >
          <div
            style={{
              height: `${GRAPH_HEIGHT}px`,
            }}
          >
            <AutoSizer>
              {({ width, height }: Size) => (
                <AlarmGraph
                  containerWidth={width}
                  containerHeight={height}
                  id={Number(id)}
                  openAlarm={goToAlarmDetail}
                />
              )}
            </AutoSizer>
          </div>
          <NewAlarmsTable
            buildingId={Number(id)}
            onRowClick={goToAlarmDetail}
            tableHeight={
              windowHeight -
              PAGE_LAYOUT_HEIGHT -
              ESTIMATED_TABLE_HEADER_HEIGHT -
              GRAPH_HEIGHT -
              GRAPH_TABLE_GAP
            }
          />
        </div>
      </CompactContent>
      {dataSetId && (
        <NotesSidebar
          noteSource={NoteSource.WEB_ALARMS}
          assetFilterMode={NotesAssetFilterMode.TimeseriesList}
          idSet={new Set(sidebarTimeseriesIds)}
          buildings={[{ id: dataSetId, name: currentBuildingName }]}
        />
      )}
    </NewAlarmsTableFiltersProvider>
  );
}
