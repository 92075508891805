import React, { useState } from "react";
import { Button, Form, FormInstance, Select } from "antd";
import { useTranslations } from "@properate/translations";
import {
  Alarm,
  AlarmSeverity,
  FilterType,
  WithSnapshotId,
} from "@properate/common";
import { FormValues } from "@/pages/alarmSystem/ruleDetails/utils";

type Props = {
  queryAlarms: {
    data: WithSnapshotId<Alarm>[];
    isLoading: boolean;
    error: string | Error | null;
  };
  form: FormInstance<FormValues>;
};

const getUniqueValues = (array: string[]) => {
  return [...new Set(array)];
};

export function AlarmsQueryBasedSelector({ queryAlarms: alarms, form }: Props) {
  const t = useTranslations();
  const [showQueryResults, setShowQueryResults] = useState(false);

  const onSelectSeverity = (value: AlarmSeverity) => {
    const { alarm_rule_selector } = form.getFieldsValue();
    const previousValue = alarm_rule_selector?.severity || [];

    if (
      alarm_rule_selector?.severity &&
      alarm_rule_selector.severity.length > 1
    ) {
      return;
    }

    switch (value) {
      case "INFO":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([
              ...previousValue,
              "INFO",
              "WARNING",
              "ERROR",
              "CRITICAL",
            ]),
          },
        });
        break;
      case "WARNING":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([
              ...previousValue,
              "WARNING",
              "ERROR",
              "CRITICAL",
            ]),
          },
        });
        break;
      case "ERROR":
        form.setFieldsValue({
          alarm_rule_selector: {
            ...(form.getFieldsValue().alarm_rule_selector || {}),
            severity: getUniqueValues([...previousValue, "ERROR", "CRITICAL"]),
          },
        });
        break;
      default:
    }
  };

  const SEVERITY_OPTIONS: Record<AlarmSeverity, string> = {
    INFO: t("alarms.severity.INFO"),
    WARNING: t("alarms.severity.WARNING"),
    ERROR: t("alarms.severity.ERROR"),
    CRITICAL: t("alarms.severity.CRITICAL"),
  };

  const CATEGORY_OPTIONS: Record<FilterType, string> = {
    energy: t("alarms.category.energy"),
    weather: t("alarms.category.weather"),
    humidity: t("alarms.category.humidity"),
    temperature: t("alarms.category.temperature"),
    motion: t("alarms.category.motion"),
    gass: t("alarms.category.gass"),
    technical: t("alarms.category.technical"),
    ventilation: t("alarms.category.ventilation"),
    heating: t("alarms.category.heating"),
    cooling: t("alarms.category.cooling"),
    sanitary: t("alarms.category.sanitary"),
    building: t("alarms.category.building"),
  };

  return (
    <fieldset className="p-0 border-0 ml-16">
      <Form.Item<FormValues>
        label={t("alert-rule.field-severity")}
        name={["alarm_rule_selector", "severity"]}
      >
        <Select mode="tags" allowClear onSelect={onSelectSeverity}>
          {Object.entries(SEVERITY_OPTIONS).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<FormValues>
        label={t("alert-rule.field-category")}
        name={["alarm_rule_selector", "category"]}
      >
        <Select mode="tags" allowClear>
          {Object.entries(CATEGORY_OPTIONS).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className="flex gap-2 items-center">
        {t("alert-rule.found-alarms", {
          count: alarms.data ? alarms.data.length : 0,
        })}
        <Button
          size="small"
          onClick={() => setShowQueryResults(!showQueryResults)}
        >
          {showQueryResults
            ? t("alert-rule.hide-alarms")
            : t("alert-rule.show-alarms")}
        </Button>
      </div>
      {showQueryResults && (
        <Select
          key={alarms.data.map((alarm) => alarm.snapshotId).join("|")}
          mode="multiple"
          className="w-full mt-6"
          loading={alarms.isLoading}
          value={
            alarms.data ? alarms.data.map((alarm) => alarm.snapshotId) : []
          }
        >
          {alarms.data?.map((alarm) => (
            <Select.Option key={alarm.snapshotId} value={alarm.snapshotId}>
              {alarm.name ?? alarm.snapshotId}
            </Select.Option>
          ))}
        </Select>
      )}
    </fieldset>
  );
}
