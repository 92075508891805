import { Fragment, PropsWithChildren } from "react";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";

export function FloorPlanMapPinRoomStrategy(props: PropsWithChildren) {
  const [isEditing] = useFloorPlanEditMode();
  const pin = useFloorPlanPin();

  if (pin.type !== "room") {
    return null;
  }

  return (
    <Fragment>
      {isEditing ? (
        <div className="flex">{props.children}</div>
      ) : (
        <div role="button" tabIndex={0} className="flex cursor-pointer">
          {props.children}
        </div>
      )}
    </Fragment>
  );
}
