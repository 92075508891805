import { Badge, Button, Space } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { sortString } from "@properate/ui";
import { useUser } from "@properate/auth";
import { useWindowSize } from "@/hooks/useWindowSize";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { GraphModalData } from "@/pages/calculationFlow/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { AnomalySystemCollection } from "../types";
import { expandedRowRender } from "./AnomalyPerAlgorithmTable";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

interface IProps {
  loading: boolean;
  anomalySystemCollection: AnomalySystemCollection[];
  searchWords: string[];
  setShowTimeseriesData: (modalData: GraphModalData | null) => void;
}

export function AnomalyPerSystemTable({
  loading,
  anomalySystemCollection,
  searchWords,
  setShowTimeseriesData,
}: IProps) {
  const t = useTranslations();
  const user = useUser();
  const building = useCurrentBuilding();

  const { height: windowHeight } = useWindowSize();

  return (
    <TableWithoutDefaultSort
      expandable={{
        expandedRowRender: (record: AnomalySystemCollection) =>
          expandedRowRender(user.email, building.id, record, t),
      }}
      pagination={false}
      dataSource={anomalySystemCollection}
      rowKey={"systemExternalId"}
      scroll={{
        y: windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
        x: "100%",
      }}
      columns={[
        {
          key: "System",
          title: t("anomaly.table.system"),
          width: 200,
          sorter: (
            { systemExternalId: systemOne },
            { systemExternalId: systemTwo },
          ) => sortString(systemOne, systemTwo),
          render: (_, { systemExternalId }) => (
            <ProperateHighlighter
              searchWords={searchWords}
              textToHighlight={systemExternalId || ""}
              title={systemExternalId}
            />
          ),
        },
        {
          title: t("anomaly.table.currentHealth"),
          key: "status",
          align: "center",
          width: 50,
          render: (_, { latestHealth, healthTimeseries }) => {
            const status =
              latestHealth !== undefined && latestHealth.value > 0.5
                ? "success"
                : "error";
            return (
              <Space>
                <Badge status={status} />
                <Button
                  icon={<LineChartOutlined />}
                  disabled={healthTimeseries === undefined}
                  onClick={async (event) => {
                    event.stopPropagation();
                    if (healthTimeseries !== undefined)
                      setShowTimeseriesData({
                        id: healthTimeseries.id,
                        unit: "",
                      });
                  }}
                />
              </Space>
            );
          },
        },
        {
          title: t("anomaly.table.anomalyCount"),
          key: "noAnomalies",
          align: "center",
          width: 100,
          render: (_, { anomalyEvents }) => {
            return <>{anomalyEvents.length}</>;
          },
        },
      ]}
      loading={loading}
    />
  );
}
