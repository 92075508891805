import { AlarmRule, getUserNameWithEmail } from "@properate/common";
import { TableColumnsType, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { sortString, useAccordionValues } from "@properate/ui";
import { useUser } from "@properate/auth";
import { ERROR } from "@/utils/ProperateColors";
import { LineClamp } from "@/components/LineClamp";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useGetFilteredNewAlarms } from "@/features/alarms";
import { NewAlarmsTableActionColumn } from "./NewAlarmsTableActionColumn";
import { NewAlarmsTableEventsColumn } from "./NewAlarmsTableEventsColumn";
import { NewAlarmsTablePlanColumn } from "./NewAlarmsTablePlanColumn";
import {
  NewAlarmsTableNotesColumn,
  makeFilterId,
} from "./NewAlarmsTableNotesColumn";

type Props = {
  buildingId?: number;
  onRowClick: (alarm: AlarmRule) => void;
  tableHeight: number;
};

export function NewAlarmsTable({ buildingId, onRowClick, tableHeight }: Props) {
  const user = useUser();
  const { filteredAlarms, search, deleteAlarm } =
    useGetFilteredNewAlarms(buildingId);
  const { filters: noteAccordionFilters } = useAccordionValues();
  const t = useTranslations();

  const columns: TableColumnsType<AlarmRule> = [
    {
      key: "priority",
      title: t("org-alarms.table-columns.severity"),
      width: 90,
      align: "center",
      sorter: ({ priority: priorityOne }, { priority: priorityTwo }) => {
        return priorityOne - priorityTwo;
      },
      render: (_, { priority }) => priority,
      //t(`org-alarms.severity.${severity.toLowerCase()}` as MessageKey),
    },
    {
      title: t("org-alarms.table-columns.active"),
      key: "is_alarm_active",
      width: 80,
      align: "center",
      sorter: (
        { is_alarm_active: activeEventOne },
        { is_alarm_active: activeEventTwo },
      ) => (activeEventOne ? 1 : 0) - (activeEventTwo ? 1 : 0),
      render: (isActive) =>
        isActive && (
          <Tooltip
            title={t("org-alarms.table-columns.active")}
            placement="right"
          >
            <div style={{ color: ERROR, fontSize: 32 }}>&bull;</div>
          </Tooltip>
        ),
    },
    {
      title: t("org-alarms.table-columns.name"),
      key: "name",
      defaultSortOrder: "ascend",
      width: 220,
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (name) =>
        name && (
          <LineClamp title={name}>
            <SearchHighlighter search={search} text={name} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.description"),
      key: "instruction",
      sorter: (
        { instruction: instructionOne },
        { instruction: instructionTwo },
      ) => sortString(instructionOne, instructionTwo),
      render: (instruction) =>
        instruction !== undefined && (
          <LineClamp title={instruction}>
            <SearchHighlighter search={search} text={instruction} />
          </LineClamp>
        ),
    },
    /*{
      title: t("org-alarms.table-columns.timeseries"),
      key: "timeseries",
      width: 220,
      sorter: ({ timeseries: timeseriesOne }, { timeseries: timeseriesTwo }) =>
        sortString(timeseriesOne?.name, timeseriesTwo?.name),
      render: (_, { timeseries }) =>
        timeseries && (
          <LineClamp title={timeseries.name}>
            <SearchHighlighter search={search} text={timeseries.name} />
          </LineClamp>
        ),
    },*/
    {
      title: t("org-alarms.table-columns.schedule"),
      key: "plan",
      width: 160,
      render: (_, alarm) => (
        <NewAlarmsTablePlanColumn
          schedule={alarm.condition.type_specific.schedule}
        />
      ),
    },
    {
      title: t("org-alarms.table-columns.created-by"),
      key: "created_by",
      width: 220,
      sorter: ({ created_by: createdByOne }, { created_by: createdByTwo }) =>
        sortString(
          getUserNameWithEmail(createdByOne),
          getUserNameWithEmail(createdByTwo),
        ),
      render: (_, { created_by }) => (
        <SearchHighlighter
          search={search}
          text={getUserNameWithEmail(created_by)}
        />
      ),
    },
    {
      title: t("org-alarms.table-columns.events"),
      key: "alarm_event_count",
      width: 64,
      sorter: (
        { alarm_event_count: eventCountOne },
        { alarm_event_count: eventCountTwo },
      ) => (eventCountOne ?? 0) - (eventCountTwo ?? 0),
      render: (_, alarm) => <NewAlarmsTableEventsColumn alarm={alarm} />,
    },
    {
      key: "action",
      width: 44,
      render: (_, alarm) => (
        <NewAlarmsTableActionColumn
          deleteAlarm={() => deleteAlarm(alarm.alarm_id)}
          disabled={!(user.isAdmin || alarm.created_by.email === user.email)}
        />
      ),
    },
    {
      key: "notes",
      width: 44,
      render: (_, alarm) => <NewAlarmsTableNotesColumn alarm={alarm} />,
    },
  ];

  return (
    <TableWithoutDefaultSort
      virtual
      columns={columns as any}
      pagination={false}
      dataSource={filteredAlarms as any}
      rowKey="snapshotId"
      onRow={(alarm) => ({
        onClick: () => onRowClick(alarm),
      })}
      scroll={{
        y: tableHeight,
        x: "100%",
      }}
      rowIsHoverable
      size="small"
      rowClassName={(alarm: AlarmRule) => {
        const shouldHighlight =
          noteAccordionFilters.get(makeFilterId(alarm))?.active ?? false;

        return shouldHighlight ? "bg-info-bg" : "";
      }}
    />
  );
}
