import { AlarmRuleTypeName, PartialAlarmRule } from "@properate/common";
import { useTranslations } from "@properate/translations";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { getSelectTimeseriesEntries } from "../components/TimeseriesSelector/SelectTimeseries";
import { TimeseriesSelector } from "../components/TimeseriesSelector";
import { getSubmitValueEntry } from "../FormContext/utils";
import { tKey } from "./common/utils";
import {
  TimeseriesThresholdAlarmMetaFields,
  TimeseriesThresholdFormFields,
  TimeseriesThresholdTypeSpecificSummary,
} from "./TimeseriesThreshold";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDelay,
  ResponseDelaySelect,
  SelectOffsetType,
} from "./common/SelectOffset";
import { AlarmRuleType } from "./index";

export const MissingDataDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.MissingData,
  labelTranslationKey: tKey("timeseries-threshold.missing-data.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
      },
      [SelectOffsetType.Delay]: getSelectOffsetFieldDelay({ alarmRule }),
      ...getSelectTimeseriesEntries(),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
        },
      },
    };
  },
  formComponent: <MissingDataPoints />,
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  getDeviationsTable: (props) => (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.LastDatapoint,
        DeviationTableColumn.Delay,
        DeviationTableColumn.Notes,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
      {...props}
    />
  ),
};

function MissingDataPoints() {
  const t = useTranslations();
  const label = t(tKey(`timeseries-threshold.select-timeseries`));
  return (
    <TimeseriesSelector
      name={TimeseriesThresholdFormFields.Timeseries}
      selectLabel={label}
      displayLabel={label}
    >
      <ResponseDelaySelect />
    </TimeseriesSelector>
  );
}
