import { useTranslations } from "@properate/translations";
import { App, Button, Divider, Input } from "antd";
import { useEffect, useMemo, useState } from "react";
import dayjs from "@properate/dayjs";
import useSWR from "swr";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { getUserNameWithEmail, IncidentClientSide } from "@properate/common";
import { listUsers, postNote, updateIncident } from "@/eepApi";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { NoteLevel, NoteSource } from "@/features/notes";
import { cogniteClient } from "@/services/cognite-client";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";

type Props = {
  alarmEventIds: number[];
  incident: IncidentClientSide;
};

const queryClient = new QueryClient();

type Comment = {
  id: number;
  createdTime: Date;
  createdBy: string;
  content: string;
};

export const IncidentActivity = ({ alarmEventIds, incident }: Props) => {
  const t = useTranslations();
  const incidentId = incident.id!;
  const { notification } = App.useApp();
  const currentBuilding = useCurrentBuilding();
  const { client } = useCogniteClient();

  const [comment, setComment] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { data: users } = useSWR("listUsers" || null, () => listUsers());

  const { data: comments = [], isLoading } = useQuery<Comment[]>(
    {
      queryKey: ["comments", incidentId],
      queryFn: () => {
        return (
          cogniteClient.events
            .list({
              filter: {
                metadata: { sourceId: incidentId },
              },
              sort: {
                createdTime: "desc",
              },
            })
            .then((events) => {
              return events.items?.map((event) => ({
                id: event.id,
                createdTime: event.createdTime,
                createdBy: event.metadata!.created_by,
                content: event.metadata!.content,
              }));
            }) || []
        );
      },
    },
    queryClient,
  );

  const {
    data,
    isLoading: isLoadingEvent,
    error: errorLoadingEvent,
  } = useSWR(["alarmEventById", alarmEventIds[0]], () => {
    return alarmEventIds[0]
      ? client.events.retrieve([{ id: alarmEventIds[0] }])
      : [];
  });

  useEffect(() => {
    if (errorLoadingEvent) {
      console.error("Error fetching alarm event", errorLoadingEvent);
      notification.error({
        message: t("incident.errors.error-fetching-incident"),
      });
    }
  }, [errorLoadingEvent, notification, t]);

  const userNames = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          acc[user.email] = getUserNameWithEmail(user);
          return acc;
        },
        {} as Record<string, string>,
      ) || {},
    [users],
  );

  const onSendComment = async () => {
    setIsSending(true);
    const assetIds = data?.[0]?.assetIds;
    const timeNow = new Date().valueOf();

    if (assetIds) {
      const { data: note } = await postNote({
        note: {
          dataSetId: currentBuilding.dataSetId as number,
          content: comment,
          startTime: (data?.[0]?.startTime || timeNow) as number,
          endTime: (data?.[0]?.endTime || timeNow) as number,
          assetIds: assetIds,
          level: NoteLevel.INFO,
          source: NoteSource.WEB_INCIDENTS,
          sourceId: incidentId,
        },
        create: true,
      });
      updateIncident(incidentId, {
        ...incident,
        last_comment: comment,
      });
      queryClient.setQueryData(
        ["comments", incidentId],
        (oldData: Comment[]) => {
          return [
            {
              id: note.id,
              createdTime: note.createdTime,
              createdBy: note.metadata!.created_by,
              content: note.metadata!.content,
            },
            ...oldData,
          ];
        },
      );
      setComment("");
    } else {
      notification.error({
        message: t("incident.errors.error-adding-comment"),
      });
      console.error("No assetIds found");
    }
    setIsSending(false);
  };

  if (isLoading) {
    return (
      <SpinnerWithDelay isLoading>
        <div />
      </SpinnerWithDelay>
    );
  }

  return (
    <div className="lg:h-full overflow-hidden flex flex-col min-h-[300px]">
      <h2 className="mb-2">{t("incident.activity")}</h2>
      <div className="flex flex-col">
        <Input.TextArea
          rows={4}
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          placeholder={"Comment"}
          className="max-h-[200px]"
        />
        <Button
          type="primary"
          className="ml-auto mt-2"
          onClick={onSendComment}
          loading={isSending || isLoadingEvent}
          disabled={!comment}
        >
          {t("incident.add")}
        </Button>
      </div>
      <div className="overflow-y-auto h-[40vh] lg:h-auto">
        {comments.map((comment) => (
          <div key={comment.id} className="flex flex-col">
            <div className="flex gap-1 text-xs items-center mb-1 opacity-60">
              <div className="font-semibold">
                {userNames[comment.createdBy] || comment.createdBy}
              </div>
              <div>{dayjs(comment.createdTime).format("DD.MM.YYYY HH:mm")}</div>
            </div>
            <div>{comment.content}</div>
            {<Divider className="my-4" />}
          </div>
        ))}
      </div>
    </div>
  );
};
